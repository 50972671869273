import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import './PaymentPage.css';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
  const [payments, setPayments] = useState([]);
  const [filterUnverified, setFilterUnverified] = useState(false);
  const [coupons, setCoupons] = useState({});
  const validatedCoupons = useRef(new Set()); // Persist validated user IDs without causing re-renders
  const navigate = useNavigate();

  console.log("payments", payments);

  // Fetch all payments from the backend when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      navigate('/admin-login');
      alert('You are not authorized to view this page. Please log in.');
      return;
    }

    const fetchPayments = async () => {
      try {
        const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
        const response = await axios.get(`${backendUrl}/api/payments`); // Adjust the API endpoint if necessary
        setPayments(response.data.payments);
      } catch (error) {
        console.error('Error fetching payments:', error);
      }
    };

    fetchPayments();
  }, [navigate]);

  // Toggle the filter for unverified payments
  const toggleFilterUnverified = () => {
    setFilterUnverified(!filterUnverified);
  };

  // Filter payments if the filter is applied
  const filteredPayments = filterUnverified
    ? payments.filter((payment) => !payment.verifiedPayment)
    : payments;

  // Function to verify a payment
  const handleVerifyPayment = async (paymentId) => {
    try {
      const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      await axios.put(`${backendUrl}/api/payments/verify/${paymentId}`);
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === paymentId ? { ...payment, status: 'accepted' } : payment
        )
      );
    } catch (error) {
      console.error('Error verifying payment:', error);
    }
  };

  // Function to reject a payment (set status to 'rejected')
  const handleRejectPayment = async (paymentId) => {
    try {
      const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      await axios.put(`${backendUrl}/api/payments/reject/${paymentId}`);
      setPayments((prevPayments) =>
        prevPayments.map((payment) =>
          payment._id === paymentId ? { ...payment, status: 'rejected' } : payment
        )
      );
    } catch (error) {
      console.error('Error rejecting payment:', error);
    }
  };

  // Check if a coupon is valid
  const handleIsCouponValid = async (id) => {
    const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
    try {
      const response = await axios.post(`${backendUrl}/api/coupons/isApplied`, {
        userId: id,
      });
      setCoupons((prev) => ({ ...prev, [id]: response.data.coupon }));
    } catch (error) {
      console.error('Coupon validation error:', error);
      setCoupons((prev) => ({ ...prev, [id]: null }));
    }
  };

  // Fetch coupons for filtered payments
  useEffect(() => {
    const fetchCoupons = async () => {
      const promises = filteredPayments
        .filter((payment) => payment.userId && !validatedCoupons.current.has(payment.userId))
        .map(async (payment) => {
          validatedCoupons.current.add(payment.userId); // Mark as validated
          await handleIsCouponValid(payment.userId);
        });

      await Promise.all(promises); // Process all coupon validations in parallel
    };

    fetchCoupons();
  }, [filteredPayments]);

  // Calculate the total sum of accepted payments
  // moved here
  const sumOfAcceptedPayments = payments
    .filter((payment) => payment.status === 'accepted')
    .reduce((total, payment) => total + payment.amountPaid, 0);

  return (
    <div className="admin-payments-container">
      <h1>Admin Payments</h1>
      <button className="filter-button" onClick={toggleFilterUnverified}>
        {filterUnverified ? 'Show All Payments' : 'Show Only Unverified Payments'}
      </button>

      <div className="table-container">
        <table className="payments-table">
          <thead>
            <tr>
              <th>User</th>
              <th>Email</th>
              <th>Tier</th>
              <th>Amount Paid</th>
              <th>Ref. Number</th>
              <th>Status</th>
              <th>Coupon Applied</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredPayments.length > 0 ? (
              filteredPayments.map((payment) => (
                <tr key={payment._id}>
                  <td>{payment.username}</td>
                  <td>{payment.email}</td>
                  <td>{payment.tier}</td>
                  <td>{payment.amountPaid}</td>
                  <td>{payment.refNumber}</td>
                  <td>
                    <span>{payment.status}</span>
                  </td>
                  <td>
                    {coupons[payment.userId] ? (
                      <span>{coupons[payment.userId].code}</span>
                    ) : (
                      <span>No coupon applied</span>
                    )}
                  </td>
                  <td>
                    <button
                      className="verify-button"
                      onClick={() => handleVerifyPayment(payment._id)}
                      disabled={payment.status !== 'pending'}
                    >
                      Accept
                    </button>
                    <button
                      className="reject-button"
                      onClick={() => handleRejectPayment(payment._id)}
                      disabled={payment.status !== 'pending'}
                    >
                      Reject
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="8">No payments found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="sum-of-accepted-payments">
        <p>Total Accepted Payments: {sumOfAcceptedPayments}</p>
      </div>
    </div>
  );
};

export default PaymentPage;
