import React, { useState, useContext } from "react";
import { Navigate } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { AuthContext } from "../../authContext/authContext";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import rightImage from "./image/etf.png";
import "./Login.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const { setAuthData } = useContext(AuthContext);
  const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const nodeBackendUrl = process.env.REACT_APP_NODE_BACKEND_URL;
      const pythonBackendUrl = process.env.REACT_APP_PYTHON_BACKEND_URL;

      console.log("Node Backend URL:", nodeBackendUrl);
      console.log("Python Backend URL:", pythonBackendUrl);
      const response = await axios.post(`${backendUrl}/api/auth/login`, {
        email,
        password,
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          localStorage.setItem("authToken", data.token);
          setAuthData(data.user);
          toast.success("Login successful!");
          setRedirect(true);
        } else {
          const errorMsg = response.data.msg || "Login failed";
          toast.error(errorMsg);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  if (redirect) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <ToastContainer />
      <div className="login-page-container">
        {/* left section */}
        <div className="login-left">
          <div className="login-container">
            <div>
              <h2>Login</h2>
            </div>

            <div className="login-form-container">
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label htmlFor="email">
                    <div className="form-label-login">
                      <span>Email</span>
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                    </div>
                  </label>

                  <label htmlFor="password">
                    <div className="form-label-login">
                      <span>Password</span>
                    </div>
                    <div className="form-input">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="password-toggle-icon"
                      >
                        <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
                      </span>
                    </div>
                  </label>

                  <button type="submit" className="login-button">
                    Login
                  </button>
                </div>
                <div className="message-container">
                  <p className="message-item">
                    New here? <a href="/register">Create an account</a>
                  </p>
                  <p className="message-item">
                    <a href="/forgot-password">Forgot Password</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="login-right">
          <img src={rightImage} alt="Kitchen Scene" />
        </div>
      </div>
    </>
  );
};

export default Login;
