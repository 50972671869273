import React, { useState, useContext, useEffect, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import { FaEye, FaEyeSlash, FaHandHoldingHeart } from 'react-icons/fa';
import { IoMdDownload } from 'react-icons/io';
import SHOONYA from '../ProfilePage/Broker-logos/SHOONYA.png';
import ANGEL from '../ProfilePage/Broker-logos/ANGEL.png';
import './ProfilePage.css';
import { Link, useNavigate } from 'react-router-dom';
import { IoIosLogOut } from "react-icons/io";
import { FaGift } from "react-icons/fa";
import Feedback from '../Feedback/Feedback';

const ProfilePage = () => {
  const { authData } = useContext(AuthContext);
  const [creds, setCreds] = useState({
    uid: '',
    pwd: '',
    token: '',
    api_key: '',
    type: 'Select ...',
  });
  const [isEditing, setIsEditing] = useState(false);
  const [isDropdownInvalid, setIsDropdownInvalid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showOverlayFeedback, setShowOverlayFeedback] = useState(false);
  const formRef = useRef(null);
  const nodeBackend = process.env.REACT_APP_NODE_BACKEND_URL;
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken'); 

    useEffect(() => {
      if (!token) {
        window.location.href = "/login";
      }
    }, []);

  useEffect(() => {
    if (!authData?.creds) {
      setShowOverlay(true);
    }
  }, []);

  const handleCredsChange = (e) => {
    const { name, value } = e.target;
    setCreds((prevCreds) => ({ ...prevCreds, [name]: value }));
    if (name === 'type' && value !== 'Select ...') {
      setIsDropdownInvalid(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleCloseOverlay = (event) => {
    if (event.target === event.currentTarget) {
      setShowOverlayFeedback(false);
    }
  };

  const handleCredsSubmit = async (e) => {
    e.preventDefault();

    if (!creds.type || creds.type === 'Select ...') {
      setIsDropdownInvalid(true);
      return;
    }

    if (token) {
      const date = new Date().toISOString();

      try {
        if (creds.type === 'Angel' && (!creds.pwd || creds.pwd.length !== 4 || isNaN(creds.pwd))) {
          alert('Please enter a valid 4-digit PIN');
          return;
        }

        const vc = `${creds.uid}_U`;
        const imei = 'abc1234';
        const credentials = { ...creds, vc, imei, date };

        const validateResponse = await axios.post(
          `${process.env.REACT_APP_PYTHON_BACKEND_URL}/api/validate-creds`,
          { credentials },
          { headers: { Authorization: `Bearer ${token}` } }
        );

        if (validateResponse.data.success) {
          const saveResponse = await axios.post(
            `${nodeBackend}/api/creds/save-creds`,
            { userId: authData._id, ...credentials },
            { headers: { Authorization: `Bearer ${token}` } }
          );

          if (saveResponse.data.success) {
            setIsEditing(false);

            if (!authData.creds) {
              const runHoldingResponse = await axios.post(
                `${process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER}/api/save-holdings`,
                { userId: authData._id, ...credentials },
                { headers: { Authorization: `Bearer ${token}` } }
              );
              if (!runHoldingResponse.data.success) {
                alert(runHoldingResponse.data.message);
                return;
              }
            }

            authData.creds = true;
            alert('Credentials saved/updated successfully!');

            if (authData.automated) {
              try {
                await axios.post(
                  `${process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER}/api/strategy/stop`,
                  { userId: authData._id },
                  { headers: { Authorization: `Bearer ${token}` } }
                );

                await axios.post(
                  `${process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER}/api/strategy/start`,
                  { userId: authData._id },
                  { headers: { Authorization: `Bearer ${token}` } }
                );

                alert('Automated strategy restarted successfully!');
              } catch (error) {
                console.error('Error restarting the automated strategy:', error);
                alert('Failed to restart the automated strategy. Please try again.');
              }
            }
            if (authData.runcode) {
              const running_response = await axios.get(`${node_backend}/api/auth/check-isrunning`, {
                headers: { Authorization: `Bearer ${token}` },
              });
              if (running_response.data.isRunning) {
                try {
                  // If already running, stop the process
                  const response_stop = await axios.post(
                    `${python_backend_server}/api/stop-runall`,
                    {},
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  console.log('Stop response:', response_stop);

                  // Start the process again
                  const response_start = await axios.post(
                    `${python_backend_server}/api/start-runall`,
                    {},
                    {
                      headers: { Authorization: `Bearer ${token}` },
                    }
                  );
                  console.log('Start response:', response_start);
                } catch (errorStopStart) {
                  console.log('Error during stop or start process:', errorStopStart);
                }
              }

            }
          } else {
            alert(saveResponse.data.message);
          }
        } else {
          alert(validateResponse.data.message);
        }
      } catch (error) {
        console.error('Error during validation or saving credentials:', error);
        alert('Failed to validate or save credentials. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (authData && authData.creds) {
      const fetchCreds = async () => {
        const token = localStorage.getItem('authToken');
        if (token) {
          const response = await axios.get(`${nodeBackend}/api/creds/get-creds/${authData._id}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          console.log(response.data);
          if (response.data.success) {
            setCreds(response.data.creds);
          }
        }
      };
      fetchCreds();
    }
  }, [authData, nodeBackend]);

  const logout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

    const handleFeedback = () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        setShowOverlayFeedback(true);
      } else {
        setShowOverlayFeedback(false);
        alert("Please login to provide feedback.");
      }
    };

  return (
    <>
      <div className="profile-page">
        <h1>User Profile</h1>
        <div className="profile-info">
          {authData ? (
            <>
              <p><strong>Email:</strong> {authData.email}</p>
              <p><strong>Plan:</strong> {authData.plan}</p>
            </>
          ) : (
            <p>Loading user data...</p>
          )}
        </div>

        {!authData?.creds || isEditing ? (
          <form className="creds-form" ref={formRef} onSubmit={handleCredsSubmit}>
            <h2>{authData?.creds ? 'Edit Credentials' : 'Enter Credentials'}</h2>

            <label>
              Credential Type:
              <select
                name="type"
                value={creds.type}
                onChange={handleCredsChange}
                className={isDropdownInvalid ? 'invalid-dropdown' : ''}
              >
                <option value="Select ...">Select ...</option>
                <option value="Shoonya">Shoonya</option>
                <option value="Angel">Angel One</option>
              </select>
            </label>
            <label>
              UID:
              <input
                type="text"
                name="uid"
                placeholder="Enter your User ID"
                value={creds.uid}
                onChange={handleCredsChange}
                required
              />
            </label>
            <label>
              {creds.type === 'Angel' ? '4-digit Login PIN' : 'Password'}:
              <div className="password-input">
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="pwd"
                  placeholder={creds.type === 'Angel' ? 'Enter 4-digit PIN' : 'Enter your password'}
                  value={creds.pwd}
                  onChange={handleCredsChange}
                  maxLength={creds.type === 'Angel' ? 4 : undefined}
                  pattern={creds.type === 'Angel' ? '\\d{4}' : undefined}
                  required
                />
                <span onClick={togglePasswordVisibility}>
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </div>
            </label>
            <label>
              TOTP Code:
              <input
                type="text"
                name="token"
                placeholder="Enter your TOTP code"
                value={creds.token}
                onChange={handleCredsChange}
                required
              />
            </label>
            <label>
              API Key:
              <input
                type="text"
                name="api_key"
                placeholder="Enter your API key"
                value={creds.api_key}
                onChange={handleCredsChange}
                required
              />
            </label>
            <button type="submit" className="save-creds-btn">
              {authData?.creds ? 'Update Credentials' : 'Save Credentials'}
            </button>
          </form>
        ) : (
          <>
            <div className='referral-link-div'>
              <Link to="/referrals" className="referral-link-section">
                <div>
                  <FaGift /> <span>Refer your friends and earn! Click here.</span>
                </div>
              </Link>
            </div>
            <div className='edit-creds-btns'>
              <button
                onClick={() => {
                  setIsEditing(true);
                  setShowOverlay(true);
                }}
                className="edit-creds-btn"
              >
                Edit Credentials
              </button>
              <button
                onClick={logout}
                className="edit-creds-btn-logout"
              >
                <div className='edit-creds-btn-logout-div'>
                  <span>Logout</span> <IoIosLogOut />
                </div>
              </button>
            </div>
          </>
        )}

        {showOverlay && (
          <div className="overlay">
            <div className="overlay-content">
              <h3>Download Instructions</h3>
              <div className="downloadables">
                <div className="download">
                  <img src={SHOONYA} alt="Shoonya broker logo" />
                  <a href="/downloadables/credentials.pdf" download>Shoonya <IoMdDownload /></a>
                </div>
                <div className="download">
                  <img src={ANGEL} alt="Angel broker logo" />
                  <a href="/downloadables/angel-credentials.pdf" download>Angel One <IoMdDownload /></a>
                </div>
              </div>
              <button className="close" onClick={() => setShowOverlay(false)}>Close</button>
            </div>
          </div>
        )}
      </div>

      {showOverlayFeedback && (
        <div className="overlay" onClick={handleCloseOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <Feedback setShowOverlay={setShowOverlayFeedback} />
          </div>
        </div>
      )}

      <div className='feedback-btn-div'>
        <button className='feedback-btn' onClick={handleFeedback}>
          <div className='feedback-inside-btn-div'>
            <div className='feedback-icon'><FaHandHoldingHeart /></div>
            <div>FEEDBACK</div>
          </div>
        </button>
      </div>
    </>
  );
};

export default ProfilePage;
