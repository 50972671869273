import React, { useEffect, useRef, useState } from "react";
import MDEditor from "@uiw/react-md-editor";
import axios from "axios";
import "./Blogs.css";
import { useNavigate } from "react-router-dom";

const PostBlog = () => {
  const creatorRef = useRef();
  const titleRef = useRef();
  const descriptionRef = useRef();
  const imageRef = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [content, setContent] = useState("");
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const adminToken = localStorage.getItem("adminToken");
  const navigate = useNavigate();

  useEffect(() => {
    if (!adminToken) {
      window.location.href = "/admin-login";
    }
  }, []);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const creator = creatorRef.current.value;
    const title = titleRef.current.value;
    const description = descriptionRef.current.value;
    const image = imageRef.current.value;

    try {
      const response = await axios.post(
        `${node_backend}/api/blogs/blog`,
        {
          creator,
          title,
          description,
          image,
          content,
        },
        {
          headers: {
            "Content-Type": "application/json" ,
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      console.log(response.data);
      if (response.data) {
        setTimeout(() => {
          navigate("/admin");
        }, 1100);
      }
    } catch (error) {
      console.error("Error posting blog:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="post-blog-container">
      <h1>Create a Blog Post</h1>
      <input
        type="text"
        className="input-field"
        placeholder="Creator"
        ref={creatorRef}
      />
      <input
        type="text"
        className="input-field"
        placeholder="Blog Title"
        ref={titleRef}
      />
      <input
        type="text"
        className="input-field"
        placeholder="Blog Description"
        ref={descriptionRef}
      />
      <input
        type="text"
        className="input-field"
        placeholder="Blog image URL"
        ref={imageRef}
      />
      <div data-color-mode="light">
        <label htmlFor="editor-label">Full blog:</label>
        <MDEditor
          height={300}
          value={content}
          onChange={setContent}
        />
      </div>
      <button
        onClick={handleSubmit}
        disabled={isSubmitting}
        className="submit-button"
      >
        {isSubmitting ? "Posting..." : "Post Blog"}
      </button>
    </div>
  );
};

export default PostBlog;