import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import { Link } from 'react-router-dom';
import './HomePage.css';
import UserGuidelines from '../userGuidelines/Userguidelines';
import { FaHandHoldingHeart } from "react-icons/fa";
import CustomizedSteppers from '../userGuidelines/Userguidelines';
import Feedback from '../Feedback/Feedback';
import { toast } from 'react-toastify';

const HomePage = () => {
  const { authData } = useContext(AuthContext);
  console.log(authData)
  const [username, setUsername] = useState(authData?.username);
  const [showOverlay, setShowOverlay] = useState(false);
  const [investmentData, setInvestmentData] = useState({
    totalInvestment: 0,
    deployedCapital: 0,
    numETFs: 0,
    profitLoss: 0,
    profitLossPercent: 0
  });
  const [loading, setLoading] = useState(true);
  const [loginFailed, setLoginFailed] = useState(false);
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

    useEffect(() => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        window.location.href = "/login";
      }
    }, []);

      const handleFeedback = () => {
        const token = localStorage.getItem('authToken');
        if (token) {
          setShowOverlay(true);
        } else {
          setShowOverlay(false);
          alert("Please login to provide feedback.");
        }
      };

      const handleCloseOverlay = (event) => {
        if (event.target === event.currentTarget) {
          setShowOverlay(false);
        }
      };
  
  useEffect(() => {
    const fetchUsernameAndPortfolio = async () => {
      if (authData?.plan !== 'Free' && authData?.creds) { // Check if plan is not Free
        try {
          // Fetch username
          const userResponse = await axios.post(
            `${python_backend}/api/get-username`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
          );
          if (userResponse.data.success && userResponse.data.uname) {
            setUsername(userResponse.data.uname);
          } else if (userResponse.data.message.includes("Login failed")) {
            setLoginFailed(true);
          }

          // Fetch portfolio and cash
          const portfolioResponse = await axios.post(
            `${python_backend}/api/fetch-portfolio-homepage`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
          );
          const cashResponse = await axios.post(
            `${python_backend}/api/fetch-cash`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` } }
          );

          if (portfolioResponse.data.success && cashResponse.data.success) {
            const { total_investment, etf_investment, etf_count, portfolio } = portfolioResponse.data;
            const cash = cashResponse.data.cash;

            // Calculate profit/loss and round values
            const currentETFValue = portfolio.reduce((sum, etf) => sum + etf.current_amount, 0);
            const profitLoss = currentETFValue - etf_investment;
            const profitLossPercent = ((profitLoss / etf_investment) * 100).toFixed(2);

            setInvestmentData({
              totalInvestment: (total_investment + cash).toFixed(2) ,
              deployedCapital: etf_investment.toFixed(2),
              numETFs: etf_count,
              profitLoss: profitLoss.toFixed(2),
              profitLossPercent: profitLossPercent
            });
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };
    fetchUsernameAndPortfolio();
  }, [authData, python_backend]);

    // Toast function to show success message
    const handleFeedbackSuccess = () => {
      toast.success("Feedback submitted successfully!", { autoClose: 2000 });
      setShowOverlay(false);
    };

  return (
    <>
    <div className='home-page-user-guidelines'>
      <UserGuidelines />
    </div>

    {/* <CustomizedSteppers plan={authData?.plan}/> */}
    <div className="home-page-container">
      <div className="home-page-main-content">
        <div className={`user-info-container ${authData?.plan}`}>
          <h1>Welcome, {username}!</h1>
          <h2>Your Plan: {authData?.plan}</h2>
        </div>

        {/* Investment Summary Section */}
        <div className="investment-summary">
          {authData?.plan === 'Free' ? (
            <p>Upgrade to <a href="/products">Gold or Silver</a> to access more features.</p>
          ) : loading ? (
            <div className="loading-spinner">Loading...</div>
          ) : (
            <p>




              {!authData?.creds ? (
                <p>
                  You have not provided the credentials.
                </p>
              ) : loginFailed ? (
                <div className="login-failed-message">
                </div>
              ) : (
                <div className={`homepage-portfolio-details {authData.creds}`}>

                  You have invested a total of <span className="highlight">Rs {investmentData.totalInvestment}</span> in the account. Out of this,
                  <span className="highlight"> Rs {investmentData.deployedCapital}</span> is being used to buy <span className="highlight">{investmentData.numETFs}</span> ETFs.
                  Your holdings are in a <span className={`highlight ${investmentData.profitLoss >= 0 ? 'profit' : 'loss'}`}>
                    {investmentData.profitLoss >= 0 ? 'profit' : 'loss'} of Rs {investmentData.profitLoss} ({investmentData.profitLossPercent}%)
                  </span> of your deployed capital.
                </div>)}
            </p>
          )}
        </div>

        {/* Additional Credential Instructions */}
        <div className="credential-instructions">
          {authData?.plan === 'Free' ? (
            <p>
              Create your portfolio. <Link to="/portfolio">Go to Portfolio</Link>
            </p>
          ) : authData?.plan === 'Gold' || authData?.plan === 'Silver' ? (
            <div>
              {!authData?.creds ? (
                <div>
                  <p>Ready to start trading? Download the instructions below to provide your credentials and begin!</p>
                    <div className="download-instructions">
                      <div className='download-buttons'>

                      <a href="/downloadables/credentials.pdf" download className="download-button">Shoonya Instructions</a>
                      <a href="/downloadables/angel-credentials.pdf" download className="download-button">Angel One Instructions</a>
                      </div>
                    </div>
                  <p>
                    Go to <a href="/profile">View Profile</a> to submit your credentials.
                  </p>
                </div>
              ) : loginFailed ? (
                <div className="login-failed-message">
                  <p>
                    Your credentials have expired or are invalid. Please <a href="/profile">click here</a> to reset your credentials.
                  </p>
                </div>
              ) : (
                <p className='homepage-portfolio-details'>
                  <Link to="/portfolio">View Your Portfolio</Link>
                </p>
              )}
            </div>
          ) : null}
        </div>


      </div>
    </div>
    {showOverlay && (
        <div className="overlay" onClick={handleCloseOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <Feedback setShowOverlay={setShowOverlay} onFeedbackSuccess={handleFeedbackSuccess} />
          </div>
        </div>
      )}
    <div className='feedback-btn-div'>
        <button className='feedback-btn' onClick={handleFeedback}>
          <div className='feedback-inside-btn-div'>
            <div className='feedback-icon'><FaHandHoldingHeart /></div>
            <div>FEEDBACK</div>
          </div>
        </button>
    </div>
    </>
  );
};

export default HomePage;
