import axios from 'axios';
import { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import "./graph.css";

export default function OrdersPlaced() {
  const [ordersData, setOrdersData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  const fetchOrdersData = async () => {
    try {
      const response = await axios.get(`${node_backend}/api/graphs/orders`);
      setOrdersData(response.data.data);
      setTotalOrders(response.data.totalOrders);
    } catch (error) {
      console.error("Error fetching orders data:", error);
    }
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Orders: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };  

  useEffect(() => {
    fetchOrdersData();
  }, []);

  // Calculate cumulative order count by date
  const chartDataOrders = ordersData.reduce((acc, data, index) => {
    const cumulativeCount = (acc[index - 1]?.count || 0) + data.count;
    acc.push({
      name: data.date, // assuming 'date' is the date
      count: cumulativeCount,
    });
    return acc;
  }, []);

  return (
    <div className='graph-container'>
      <div>
        <h4 className="graph-title">
          Orders Placed 
          <span className='graph-heading-span'>
            (Total Orders: {totalOrders})
          </span>
        </h4>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={chartDataOrders}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#F7BB67" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F7BB67" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis
              tick={{ display: 'none' }}
              label={{ value: '', position: 'insideBottom' }}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Area 
              type="monotone"
              dataKey="count"
              stroke="#F7BB67"
              fill="url(#colorUv)"
              fillOpacity={1}
              strokeWidth={2}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
