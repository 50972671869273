import BlogCard from "../../components/BlogCard/BlogCard";
import "./BlogList.css";

const BlogList = () => {
  return (
    <>
      <div className="blog-list-page">
        <div className="blog-list-header">
          <h1>Blogs</h1>
        </div>
        <div className="blog-list-container">
          <BlogCard />
        </div>
      </div>
    </>
  );
};

export default BlogList;
