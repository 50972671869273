import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './DummyPortfolio.css'; // Import the CSS file

const Portfolio = () => {
    const { authData } = useContext(AuthContext);
    const [etfs, setEtfs] = useState([]);
    const [portfolio, setPortfolio] = useState([]);
    const [symbol, setSymbol] = useState('');
    const [avgBuyPrice, setAvgBuyPrice] = useState('');
    const [quantity, setQuantity] = useState('');
    const [sortKey, setSortKey] = useState(null); // Column to sort by
    const [sortOrder, setSortOrder] = useState('asc');
    const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

    useEffect(() => {
        const fetchEtfs = async () => {
            try {
                const response = await axios.get(`${node_backend}/api/etf/etfs`);
                setEtfs(response.data);
            } catch (error) {
                console.error('Error fetching ETFs:', error);
            }
        };
        fetchEtfs();
    }, [node_backend]);

    useEffect(() => {
        if (authData && authData.email) {
            const fetchPortfolio = async () => {
                try {
                    const response = await axios.get(`${node_backend}/api/portfolio/view`, {
                        params: { email: authData.email },
                    });
                    const updatedPortfolio = response.data.map((entry) => {
                        const etf = etfs.find((etf) => etf.symbol === entry.symbol);
                        const currentPrice = etf ? etf.price : 0;
                        const netGainLoss = (currentPrice && entry.avgBuyPrice && entry.quantity)
                            ? (currentPrice - entry.avgBuyPrice) * entry.quantity
                            : 0;
                        const percentageGainLoss = (currentPrice && entry.avgBuyPrice)
                            ? ((currentPrice - entry.avgBuyPrice) / entry.avgBuyPrice) * 100
                            : 0;
                        return {
                            ...entry,
                            currentPrice,
                            netGainLoss,
                            percentageGainLoss,
                        };
                    });
                    setPortfolio(updatedPortfolio);
                } catch (error) {
                    console.error('Error fetching portfolio:', error);
                }
            };
            fetchPortfolio();
        }
    }, [authData, etfs, node_backend]);

    const addToPortfolio = async (e) => {
        e.preventDefault();
        if (authData && authData.email) {
            // Validate input
            const avgBuyPriceNum = parseFloat(avgBuyPrice);
            const quantityNum = parseInt(quantity);
            if (isNaN(avgBuyPriceNum) || avgBuyPriceNum <= 0) {
                alert('Average Buy Price must be a number greater than 0.');
                return;
            }
            if (!Number.isInteger(quantityNum) || quantityNum <= 0) {
                alert('Quantity must be a natural number greater than 0.');
                return;
            }

            try {
                const newEntry = { symbol, avgBuyPrice: avgBuyPriceNum, quantity: quantityNum };
                const response = await axios.post(`${node_backend}/api/portfolio/add`, {
                    email: authData.email,
                    ...newEntry,
                });
                window.location.reload();

                // const updatedPortfolio = response.data;
                // setPortfolio(updatedPortfolio); // Assume backend returns the updated portfolio
                // setSymbol('');
                // setAvgBuyPrice('');
                // setQuantity('');
            } catch (error) {
                console.error('Error adding to portfolio:', error);
            }
        }
    };

    const deleteFromPortfolio = async (symbolToDelete) => {
        if (authData && authData.email) {
            try {
                await axios.post(`${node_backend}/api/portfolio/delete`, {
                    email: authData.email,
                    symbol: symbolToDelete,
                });
                setPortfolio(portfolio.filter((item) => item.symbol !== symbolToDelete));
            } catch (error) {
                console.error('Error deleting from portfolio:', error);
            }
        }
    };

    const handleSort = (key) => {
        const order = sortKey === key && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortKey(key);
        setSortOrder(order);

        const sortedPortfolio = [...portfolio].sort((a, b) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA < valueB) return order === 'asc' ? -1 : 1;
            if (valueA > valueB) return order === 'asc' ? 1 : -1;
            return 0;
        });
        setPortfolio(sortedPortfolio);
    };

    return (
        <div className="portfolio-wrapper">
            <form onSubmit={addToPortfolio} className="portfolio-form-section">
                <div className='dummyportfolio-form-heading'>
                    <h3>Create Your Portfolio</h3>
                </div>
                <div className='dummyportfolio-form-body'>
                    <div className='dummyportfolio-form-symbol'>

                        <div className="enhanced-dropdown-container">
                            <select
                                className="enhanced-dropdown"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                                required
                            >
                                <option value="" disabled>
                                    Select Symbol
                                </option>
                                {etfs.map((etf) => (
                                    <option key={etf.symbol} value={etf.symbol}>
                                        {etf.symbol} - ₹{etf.price}
                                    </option>
                                ))}
                            </select>
                        </div>


                    </div>
                    <div className='dummyportfolio-form-avgbuyprice'>

                        <input
                            type="number"
                            value={avgBuyPrice}
                            onChange={(e) => setAvgBuyPrice(e.target.value)}
                            placeholder="Average Buy Price"
                            required
                        />
                    </div>

                    <div className='dummyportfolio-form-qty'>

                        <input
                            type="number"
                            value={quantity}
                            onChange={(e) => setQuantity(e.target.value)}
                            placeholder="Quantity"
                            required
                        />
                    </div>
                    <div className='dummyportfolio-form-submitbutton'>
                        <button type="submit">Add to Portfolio</button>
                    </div>
                </div>
            </form>

            <table className="portfolio-data-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('symbol')} style={{ cursor: 'pointer' }}>
                            Symbol {sortKey === 'symbol' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('avgBuyPrice')} style={{ cursor: 'pointer' }}>
                            Average Buy Price {sortKey === 'avgBuyPrice' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('quantity')} style={{ cursor: 'pointer' }}>
                            Quantity {sortKey === 'quantity' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('currentPrice')} style={{ cursor: 'pointer' }}>
                            Current Price {sortKey === 'currentPrice' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('netGainLoss')} style={{ cursor: 'pointer' }}>
                            Net Gain/Loss {sortKey === 'netGainLoss' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th onClick={() => handleSort('percentageGainLoss')} style={{ cursor: 'pointer' }}>
                            Percentage Gain/Loss {sortKey === 'percentageGainLoss' && (sortOrder === 'asc' ? '▲' : '▼')}
                        </th>
                        <th>Actions</th>
                    </tr>
                </thead>

                <tbody>
                    {portfolio.map((entry) => (
                        <tr key={entry.symbol}>
                            <td>{entry.symbol}</td>
                            <td>₹{entry.avgBuyPrice.toFixed(2)}</td>
                            <td>{entry.quantity}</td>
                            <td>₹{entry.currentPrice.toFixed(2)}</td>
                            <td className={entry.netGainLoss >= 0 ? 'gain-positive' : 'gain-negative'}>
                                {entry.netGainLoss >= 0 ? '+' : ''}₹{entry.netGainLoss.toFixed(2)}
                            </td>
                            <td className={entry.percentageGainLoss >= 0 ? 'gain-positive' : 'gain-negative'}>
                                {entry.percentageGainLoss >= 0 ? '+' : ''}{entry.percentageGainLoss.toFixed(2)}%
                            </td>
                            <td>
                                <button onClick={() => deleteFromPortfolio(entry.symbol)} className="delete-button">Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Portfolio;
