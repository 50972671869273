import React, { useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './Modelportfolio.css';
import ETFDetailsOverlay from '../ETFDetailsOverlay/ETFDetailsOverlay';
import DummyPortfolio from '../DummyPortfolio/DummyPortfolio';
import { MdSort } from "react-icons/md";

const Modelportfolio = () => {
  const { authData } = useContext(AuthContext);
  const [portfolio, setPortfolio] = useState([]);
  const [loading, setLoading] = useState(true);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [drawer, setDrawer] = useState(false);
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;
  const drawerRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (!token) {
      window.location.href = "/login";
    }
  }, []);

  useEffect(() => {
    if (authData && (authData.plan === 'Gold' || authData.plan === 'Silver')) {
      const fetchPortfolio = async () => {
        const token = localStorage.getItem('authToken');
        try {
          const response = await axios.post(
            `${python_backend}/api/fetch-portfolio`,
            { email: authData?.email },
            { headers: { Authorization: `Bearer ${token}` } }
          );
          if (response.data.success) {
            setPortfolio(response.data.portfolio);
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching portfolio:', error);
        } finally {
          setLoading(false);
        }
      };
      fetchPortfolio();
    } else {
      setLoading(false);
    }
  }, [authData, python_backend]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setDrawer(false);
      }
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setDrawer(false);
      }
    };

    if (drawer) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drawer]);

  const handleBuySellClick = (symbol) => {
    setSelectedSymbol(symbol.replace('-EQ', ''));
    setOverlayVisible(true);
  };

  const closeOverlay = () => {
    setOverlayVisible(false);
    setSelectedSymbol(null);
  };

  const sortPortfolio = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    setPortfolio((prevPortfolio) =>
      [...prevPortfolio].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
        return 0;
      })
    );
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  if (authData && authData.plan === 'Free') {
    return <DummyPortfolio />;
  }

  const handleSortClick = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
    setPortfolio((prevPortfolio) =>
      [...prevPortfolio].sort((a, b) => {
        if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
        if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
        return 0;
      })
    );
    setDrawer(false);
  };

  const sortOptions = [
    { key: "symbol", label: "A-Z Alphabetically (Symbol)" },
    { key: "number_of_shares", label: "Quantity (Number of Shares)" },
    { key: "avg_amount_per_share", label: "Buy Price (₹)" },
    { key: "current_value_per_share", label: "Current Market Price (₹)" },
    { key: "amount_invested", label: "Amount Invested (₹)" },
    { key: "current_amount", label: "Current Value (₹)" },
    { key: "gain", label: "Gain (₹)" },
    { key: "gain_percentage", label: "Gain (%)" },
  ];

  return (
    <div className="portfolio-container">
      <h2>Your Portfolio</h2>
      <div className='sort-icon-div' onClick={() => setDrawer(!drawer)}>
          <MdSort size={25} className='sort-icon'/>
      </div>
      {drawer && (
      <div className="sort-drawer" ref={drawerRef}>
        <h3>Sort</h3>
        <ul>
          {sortOptions.map((option) => (
            <li
              key={option.key}
              onClick={() => handleSortClick(option.key)}
              className={`sorting-option ${
                sortConfig.key === option.key ? "active" : ""
              }`}
            >
              {option.label}
              {sortConfig.key === option.key && (
                <span className="sort-direction">
                  {sortConfig.direction === "ascending" ? "▲" : "▼"}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    )}
      {loading ? (
        <p>Loading your portfolio...</p>
      ) : portfolio.length > 0 ? (
        <>
        {/* Desktop Table Layout */}
        <div className="desktop-view">
          <table className="portfolio-table">
            <thead>
              <tr className="clickable-row">
                <th onClick={() => sortPortfolio('symbol')}>Symbol {getSortIcon('symbol')}</th>
                <th onClick={() => sortPortfolio('number_of_shares')}>Qty {getSortIcon('number_of_shares')}</th>
                <th onClick={() => sortPortfolio('avg_amount_per_share')}>Buy Price (₹) {getSortIcon('avg_amount_per_share')}</th>
                <th onClick={() => sortPortfolio('current_value_per_share')}>CMP (₹) {getSortIcon('current_value_per_share')}</th>
                <th onClick={() => sortPortfolio('amount_invested')}>Amount Invested (₹) {getSortIcon('amount_invested')}</th>
                <th onClick={() => sortPortfolio('current_amount')}>Current Amount (₹) {getSortIcon('current_amount')}</th>
                <th onClick={() => sortPortfolio('gain')}>Gain (₹) {getSortIcon('gain')}</th>
                <th onClick={() => sortPortfolio('gain_percentage')}>Gain (%) {getSortIcon('gain_percentage')}</th>
              </tr>
            </thead>
            <tbody>
              {portfolio.map((holding, index) => (
                <tr
                  key={index}
                  className="clickable-row"
                  onClick={() => handleBuySellClick(holding.symbol)}
                >
                  <td>{holding.symbol.replace('-EQ', '')}</td>
                  <td>{holding.number_of_shares}</td>
                  <td>{holding.avg_amount_per_share?.toFixed(2) || 'N/A'}</td>
                  <td>{holding.current_value_per_share?.toFixed(2) || 'N/A'}</td>
                  <td>{holding.amount_invested?.toFixed(2) || 'N/A'}</td>
                  <td>{holding.current_amount?.toFixed(2) || 'N/A'}</td>
                  <td className={holding.gain >= 0 ? 'gain' : 'loss'}>{holding.gain?.toFixed(2) || 'N/A'}</td>
                  <td className={holding.gain_percentage >= 0 ? 'gain' : 'loss'}>{holding.gain_percentage?.toFixed(2) || 'N/A'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Mobile Layout */}
        <div className="mobile-view">
          {portfolio.map((holding, index) => (
            <div className='portfolio-card-div' key={index}>
              <div className="portfolio-card" onClick={() => handleBuySellClick(holding.symbol)}>
                {/* Left Section */}
                <div className="left-section">
                  <div className="symbol">{holding.symbol.replace('-EQ', '')}</div>
                  <div className="details">Avg ₹{holding.avg_amount_per_share?.toFixed(2) || 'N/A'}</div>
                  <div className="details">Shares {holding.number_of_shares}</div>
                </div>
                {/* Right Section */}
                <div className="right-section">
                  <div className={holding.gain >= 0 ? 'gain' : 'loss'}>₹{holding.gain?.toFixed(2) || 'N/A'}</div>
                  <div className={holding.gain_percentage >= 0 ? 'gain-per' : 'loss-per'}>({holding.gain_percentage?.toFixed(2) || 'N/A'}%)</div>
                  <div className="details">CMP (₹) {holding.current_value_per_share?.toFixed(2) || 'N/A'}</div>
                </div>
              </div>
              <hr className='portfolio-card-div-hr'/>
              <div className='bottom-portfolio-card'>
                <div className='bottom-portfolio-card-div'>
                    <span className="details">Invested</span> 
                    <div className='bottom-portfolio-card-amount'>{holding.amount_invested?.toFixed(2) || 'N/A'}</div>
                </div>
                <div className='bottom-portfolio-card-div'>
                    <span className="details">Current</span> 
                    <div className='bottom-portfolio-card-amount'>{holding.current_amount?.toFixed(2) || 'N/A'}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
      ) : (
        <p>No holdings found.</p>
      )}

      {overlayVisible && (
        <ETFDetailsOverlay symbol={selectedSymbol} onClose={closeOverlay} />
      )}
    </div>
  );
};

export default Modelportfolio;
