import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import './OrdersPage.css';
import moment from 'moment';
import { AiOutlineLoading3Quarters } from "react-icons/ai";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const OrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [realizedGainsData, setRealizedGainsData] = useState([]);
  const [totalRealizedGains, setTotalRealizedGains] = useState(0);
  const { authData } = useContext(AuthContext);
  const pythonBackend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;
  const pythonBackend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [showTodayOrders, setShowTodayOrders] = useState(true);
  const [error, setError] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'ascending' });
  const [loading, setLoading] = useState(false); // New state for loading indicator

  const [selectedRange, setSelectedRange] = useState('');

    useEffect(() => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        window.location.href = "/login";
      }
    }, []);


  const sortedOrders = [...filteredOrders].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });
  const handleDateRange = (range) => {
    setSelectedRange(range);
    const today = moment().startOf('day');
    let start;
    if (range === '3days') {
      start = today.clone().subtract(3, 'days');
    } else if (range === '10days') {
      start = today.clone().subtract(10, 'days');
    } else if (range === 'month') {
      start = today.clone().subtract(1, 'month');
    }

    const newFilteredOrders = orders.filter(order => {
      const orderDate = moment(order.date);
      return orderDate.isSameOrAfter(start) && orderDate.isSameOrBefore(today);
    });

    setFilteredOrders(newFilteredOrders);
    setShowTodayOrders(false);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };
  useEffect(() => {
    const fetchOrders = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('authToken');
  
        if (authData && authData.email) {
          const todayResponse = await axios.get(`${pythonBackend}/api/orders-today`, {
            params: { email: authData?.email }, // Fetch today's orders
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (todayResponse.data.success) {
            setFilteredOrders(todayResponse.data.orders);
          } else {
            console.error("Error fetching today's orders:", todayResponse.data.message);
          }
  
          const allOrdersResponse = await axios.get(`${pythonBackend_server}/api/user_orders`, {
            params: { email: authData?.email }, // Fetch all user orders
            headers: { Authorization: `Bearer ${token}` },
          });
  
          if (allOrdersResponse.data.success) {
            setOrders(allOrdersResponse.data.orders);
            const sortedGainsData = allOrdersResponse.data.realized_gains_data
              .map(entry => ({ ...entry, date: new Date(entry.date) }))
              .sort((a, b) => a.date - b.date);
            setRealizedGainsData(sortedGainsData);
            setTotalRealizedGains(allOrdersResponse.data.total_realized_gains);
          } else {
            console.error("Error fetching orders:", allOrdersResponse.data.message);
          }
        }
        setLoading(false);
      } catch (error) {
        setLoading(false); // if error, set loading to false
        console.error("Error fetching orders", error);
      }
    };
  
    fetchOrders();
  }, [authData]);

  const chartData = {
    labels: realizedGainsData.map(entry => moment(entry.date).format('DD, MMM YYYY')),
    datasets: [
      {
        label: 'Realized Gains Over Time',
        data: realizedGainsData.map(entry => entry.gain),
        fill: false,
        borderColor: '#4CAF50',
        tension: 0.1,
        pointBackgroundColor: '#4CAF50',
      }
    ]
  };

  const chartOptions = {
    maintainAspectRatio: false,
    // responsive: true,
    plugins: {
      legend: { position: 'top' },
      title: { display: true, text: 'Realized Gains Over Time' }
    },
    scales: {
      x: {
        title: { display: true, text: 'Date' },
        ticks: {
          maxTicksLimit: 10,
        }
      },
      y: {
        title: { display: true, text: 'Realized Gain (₹)' }
      }
    }
  };

  const handleSubmit = () => {
    if (!startDate || !endDate) {
      setError(true);
      return;
    }
    setError(false);
    setShowTodayOrders(false);

    const newFilteredOrders = orders.filter(order => {
      const orderDate = moment(order.date);
      return (!startDate || orderDate.isSameOrAfter(moment(startDate))) &&
        (!endDate || orderDate.isSameOrBefore(moment(endDate)));
    });
    setFilteredOrders(newFilteredOrders);
  };

  const handleReset = () => {
    setStartDate('');
    setEndDate('');
    setError(false);
    setShowTodayOrders(true);

    const today = moment().startOf('day');
    const todaysOrders = orders.filter(order =>
      moment(order.date).isSame(today, 'day')
    );
    setFilteredOrders(todaysOrders);
    setSelectedRange('');
  };

  return (
    <div className="orders-page">
      <h2>Your Orders</h2>
      {loading 
      ? 
        <div className='orderpage-loading-div'> 
          <AiOutlineLoading3Quarters className='orderpage-loading'/> 
        </div> 
      : 
      <div>
        <div className="gains">
          <p>Total Realized Gains: <span>₹{totalRealizedGains.toFixed(2)}</span></p>
        </div>

        <div className="date-filter">
          <h3>Filter Orders by Date</h3>
          <label className={error ? 'error-highlight' : ''}>
            Start Date:
            <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
          </label>
          <label className={error ? 'error-highlight' : ''}>
            End Date:
            <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
          </label>
          {error && <p className="error-message">Please select both start and end dates to filter.</p>}
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={handleReset}>Reset</button>
          <div className="predefined-ranges">
            <button
            className={selectedRange === '3days' ? 'selected' : ''} 
            onClick={() => handleDateRange('3days')}>Last 3 Days</button>
            
            <button 
            className={selectedRange === '10days' ? 'selected' : ''} 
            onClick={() => handleDateRange('10days')}>Last 10 Days</button>

            <button 
            className={selectedRange === 'month' ? 'selected' : ''} 
            onClick={() => handleDateRange('month')}>Last Month</button>
          </div>

        </div>

        <div className="orders-list">
          {showTodayOrders && <h3>Today's Orders</h3>}
          {!showTodayOrders && <h3>Order History</h3>}
          {filteredOrders.length > 0 ? (
            <table className="orders-table">
              <thead>
                <tr>
                  <th onClick={() => handleSort('date')} style={{ cursor: 'pointer' }}>Date</th>
                  <th onClick={() => handleSort('symbol')} style={{ cursor: 'pointer' }}>Symbol</th>
                  <th onClick={() => handleSort('order_type')} style={{ cursor: 'pointer' }}>Order Type</th>
                  <th onClick={() => handleSort('qty')} style={{ cursor: 'pointer' }}>Quantity</th>
                  <th onClick={() => handleSort('fill_price')} style={{ cursor: 'pointer' }}>Price</th>
                </tr>
              </thead>

              <tbody>
                {sortedOrders.map((order, index) => (
                  <tr key={index}>
                    <td>{moment(order.date).format('DD, MMM YYYY')}</td>
                    <td>{order.symbol}</td>
                    <td>{order.order_type}</td>
                    <td>{order.qty}</td>
                    <td>₹{order.fill_price.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No orders found for this date range.</p>
          )}
        </div>
        <div className="canvas-container">
          <Line data={chartData} options={chartOptions} />
        </div>
      </div>}
    </div>
  );
};

export default OrdersPage;
