import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import './BlogCardCarousel.css';
import axios from 'axios';

const BlogCardCarousel = () => {
  const [blogs, setBlogs] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const carouselRef = useRef(null);
  const startIndexRef = useRef(0);
  const timeoutRef = useRef(null);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/blogs/blogs`);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();

    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const slidesPerView = isMobile ? 1 : 3;

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: carouselRef.current.offsetWidth + 10,
        behavior: 'smooth',
      });

      // Update startIndex for infinite scroll
      startIndexRef.current = (startIndexRef.current + 1) % blogs.length;
    }
  };

  const prev = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -(carouselRef.current.offsetWidth + 10),
        behavior: 'smooth',
      });

      // Update startIndex for infinite scroll
      startIndexRef.current =
        (startIndexRef.current - 1 + blogs.length) % blogs.length;
    }
  };

  const startAutoSlide = () => {
    timeoutRef.current = setInterval(next, 4000);
  };

  const stopAutoSlide = () => {
    if (timeoutRef.current) {
      clearInterval(timeoutRef.current);
    }
  };

  useEffect(() => {
    startAutoSlide();
    return () => stopAutoSlide();
  }, [blogs]);

  // Swipe handling for touch devices
  useEffect(() => {
    let startX = 0;
    let currentX = 0;
    let isSwiping = false;

    const handleTouchStart = (e) => {
      startX = e.touches[0].clientX;
      isSwiping = true;
    };

    const handleTouchMove = (e) => {
      if (isSwiping) {
        currentX = e.touches[0].clientX;
        const deltaX = currentX - startX;
        if (deltaX > 50) {
          prev();
          isSwiping = false;
        } else if (deltaX < -50) {
          next();
          isSwiping = false;
        }
      }
    };

    const handleTouchEnd = () => {
      isSwiping = false;
    };

    const carousel = carouselRef.current;
    if (carousel) {
      carousel.addEventListener('touchstart', handleTouchStart);
      carousel.addEventListener('touchmove', handleTouchMove);
      carousel.addEventListener('touchend', handleTouchEnd);
    }

    return () => {
      if (carousel) {
        carousel.removeEventListener('touchstart', handleTouchStart);
        carousel.removeEventListener('touchmove', handleTouchMove);
        carousel.removeEventListener('touchend', handleTouchEnd);
      }
    };
  }, [blogs]);

  return (
    <div 
      className="custom-carousel-container" 
      onMouseEnter={stopAutoSlide} 
      onMouseLeave={startAutoSlide}
    >
      <button className="custom-nav-button custom-prev" onClick={prev}>
        <ChevronLeft size={24} />
      </button>
      
      <div className="custom-carousel-content" ref={carouselRef}>
        <div className="custom-carousel-slides">
          {blogs.map((blog, index) => (
            <div key={`${blog.id}-${index}`} className="custom-carousel-slide">
              <a href={`/blog/${blog.id}`} className="custom-blog-card">
                <div className="custom-blog-image">
                  <img src={blog.image} alt={blog.title} />
                </div>
                <div className="custom-blog-content">
                  <p className="custom-blog-date">
                    {new Date(blog.uploadedAt).toLocaleDateString()}
                  </p>
                  <h3 className="custom-blog-title">{blog.title}</h3>
                  <p className="custom-blog-description">{blog.description}</p>
                  <p className="custom-blog-author">{blog.creator}</p>
                </div>
              </a>
            </div>
          ))}
        </div>
      </div>

      <button className="custom-nav-button custom-next" onClick={next}>
        <ChevronRight size={24} />
      </button>
    </div>
  );
};

export default BlogCardCarousel;
