import React, { useState, useEffect } from "react";
import axios from "axios";
import "./BlogCard.css";
import { Link } from "react-router-dom";

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/blogs/blogs`);
        setBlogs(response.data);
      } catch (error) {
        console.error("Error fetching blogs:", error);
      }
    };
    fetchBlogs();
  }, []);

  return (
    <>
      {blogs.map((blog) => (
        <div className="blog-container" key={blog._id}>
          <Link to={`/blog/${blog.id}`} className="blog-card-link">
            <div className="blog-card">
              {/* Image */}
              {blog.image ? (
                <div className="blog-image-container">
                  <img
                    src={blog.image}
                    alt={blog.title}
                    className="blog-image"
                  />
                </div>
              ) : (
                <div className="blog-no-image">No image available</div>
              )}
    
              {/* Content */}
              <div className="blog-content">
                {/* Uploaded Date */}
                <div className="blog-date">
                  <p>
                    {new Date(blog.uploadedAt).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })}
                  </p>
                </div>
    
                {/* Title and Description */}
                <div className="blog-title-description">
                  <h2>{blog.title}</h2>
                  <p>{blog.description}</p>
                </div>
    
                {/* Author */}
                <div className="blog-author">
                  <p>By {blog.creator || "Unknown Author"}</p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      ))}
    </>
  );
};

export default BlogCard;
