import React, { useState, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { AuthContext } from "../../authContext/authContext";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminLogin.css";

const Login = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const { setAuthData } = useContext(AuthContext);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  console.log("setAuthData", setAuthData);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    try {
      const response = await axios.post(`${node_backend}/admin/login`, { email, password });
      if (response.data.success) {
        localStorage.setItem('adminToken', response.data.token);
        toast.success("Admin login successful!");
        navigate('/admin'); // Corrected redirection
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Invalid credentials");
      }
    } catch (error) {
      console.error('Login failed:', error);
      setLoading(false);
      toast.error("Login failed!");
    }
  };  

  return (
    <>
      <ToastContainer />
      <div className="admin-login-page-container">
          <div className="admin-login-container">
            <div>
              <h2>Admin Login</h2>
            </div>

            <div className="admin-login-form-container">
              <form onSubmit={handleLogin}>
                <div className="form-group">
                  <label htmlFor="email">
                    <div className="form-label-login">
                      <span>Email</span>
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="email"
                        ref={emailRef}
                        required
                      />
                    </div>
                  </label>

                  <label htmlFor="password">
                    <div className="form-label-login">
                      <span>Password</span>
                    </div>
                    <div className="form-input">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        ref={passwordRef}
                        required
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="password-toggle-icon"
                      >
                        <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
                      </span>
                    </div>
                  </label>

                  <button type="submit" className="login-button">
                    {loading ? "Logging in..." : "Login"}
                  </button>
                </div>
              </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
