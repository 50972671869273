import React, { useContext, useEffect, useState } from "react";
import { IoMdClose } from "react-icons/io";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import angel from "./Broker-logos/ANGEL.png";
import shoonya from "./Broker-logos/SHOONYA.png";
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Check from '@mui/icons-material/Check';
import { AuthContext } from "../../authContext/authContext";
import { useNavigate } from "react-router-dom";
import "./Userguidelines.css";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 10,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderColor: '#6aa84f',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
          // this is color after completing
            borderColor: '#6aa84f',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
      // this is default color change like gray
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 2,
    },
}));

const UserGuidelines = () => {
    const { authData } = useContext(AuthContext);
    const [isRegistered, setIsRegistered] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [isAccountOpenLocal, setIsAccountOpenLocal] = useState(false);
    const [totalValue, setTotalValue] = useState();
    const token = localStorage.getItem("authToken");
    const navigate = useNavigate();
    const completeValue = localStorage.getItem("brokerInegrationValueCompleted");

    useEffect(() => {
        setTotalValue(completeValue);
        setIsRegistered(!!token);
        setIsAccountOpenLocal(authData?.creds || false);
    }, [token, authData]);

    useEffect(() => {
      if(authData?.creds) {
        setTotalValue(100);
      }
    }, [])

    const steps = [
        { title: "Register", completed: isRegistered, value: isRegistered ? 100 : 0, link: "/register" },
        { title: "Broker Integration", completed: isAccountOpenLocal, value: totalValue || 0, onClick: () => setShowOverlay(true) },
        { title: "Subscribe", completed: authData?.plan !== "Free", value: authData?.plan === "Free" ? 0 : 100, link: "/products" },
        { title: "Referrals", completed: authData?.referrals?.length > 0, value: authData?.referrals?.length, link: "/referrals" },
    ];

    const getStockValRef = (value) => {
      if(value < 10) {
        return "red";
      } else if(value >= 10 && value < 50) {
        return "lightBlue";
      } else {
        return "green";
      }
    }

    return (
        <div className="user-guidelines-container">
            <ToastContainer />
            <h1 className="user-guidelines-title">User Guidelines</h1>
            {showOverlay && (
                <div className="overlay">
                    <div className="overlay-content">
                        <div className="close-icon-userguide">
                            <IoMdClose size={24} onClick={() => setShowOverlay(false)} />
                        </div>
                        <div className="overlay-title-userguide">
                            <h4>Click on your broker of choice to open an account</h4>
                        </div>
                        <div className="overlay-img-div-userguideline">
                            <div onClick={() => {
                                localStorage.setItem("brokerInegrationValueCompleted", 33);
                                window.open("https://angel-one.onelink.me/Wjgr/67cd26ge", "_blank");
                            }}>
                                <img src={angel} alt="" width={50} />
                            </div>
                            <div onClick={() => {
                                localStorage.setItem("brokerInegrationValueCompleted", 33);
                                window.open("https://shoonya.com/open-an-account/", "_blank");
                            }}>
                                <img src={shoonya} alt="" width={50} />
                            </div>
                        </div>
                        <div className="overlay-haveAccount-userguide" onClick={() => {
                            localStorage.setItem("brokerInegrationValueCompleted", 66);
                            navigate("/profile");
                        }}>
                            <p>I already have an active de-mat account</p>
                        </div>
                    </div>
                </div>
            )}
            <Stack sx={{ width: '100%' }} spacing={0}>
                <Stepper alternativeLabel connector={<QontoConnector />}>
                    {steps.map((step) => (
                        <Step key={step.title} completed={step.completed}>
                            <StepLabel
                                optional={step.completed ? <Check /> : null}
                                onClick={step.link ? () => (window.location.href = step.link) : step.onClick}
                            >
                                <div className="step-progress-container">
                                    <CircularProgressbar
                                        className="circle-progress"
                                        value={step.value}
                                        text={`${step.value}${step.title !== "Referrals" ? "%" : ""}`}
                                        styles={{
                                            path: {
                                                // stroke: step.completed ? '#784af4' : '#eaeaf0',
                                                stroke: `${step.title == "Referrals" ? getStockValRef(step.value) : "#6aa84f"}`,
                                            },
                                            text: {
                                                fill: step.completed ? '#784af4' : 'black',
                                                fontSize: '28px',
                                            },
                                        }}
                                    />
                                </div>
                                <span>{step.title}</span>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Stack>
        </div>
    );
};

export default UserGuidelines;
