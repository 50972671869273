import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { toast, ToastContainer } from "react-toastify";
import "./BlogDetail.css";

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const adminToken = localStorage.getItem("adminToken");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/blogs/blogs/${id}`);
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching blog details:", error);
      }
    };
    fetchBlog();
  }, [id]);

  if (!blog) {
    return <p>Loading...</p>;
  }

    const handleDeleteBlog = async (blogId) => {
      try {
        await axios.delete(`${node_backend}/api/blogs/blog/${blogId}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${adminToken}`,
          },
        });
        toast.success("Blog deleted successfully!", { autoClose: 1300 });
        setTimeout(() => {
          navigate("/get-blogs")
        }, 1100);
      } catch (error) {
        toast.error("Error deleting blog.");
        console.error("Error deleting blog:", error);
      }
    }

  return (
    <div className="blog-detail-container">
      <ToastContainer />
        {adminToken && (
            <div className="blog-detail-delete-icon" onClick={() => handleDeleteBlog(id)}>
            <MdDeleteForever size={25}/> <span>Delete this blog</span>
        </div>
        )}
        <br />
        <h1>{blog.title}</h1>
      <p><strong>By:</strong> {blog.creator || "Unknown Author"}</p>
      <p><strong>Published on:</strong> {new Date(blog.uploadedAt).toLocaleDateString("en-GB", { day: "2-digit", month: "short", year: "numeric" })}</p>
      <div className="blog-content">
        <div dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>
    </div>
  );
};

export default BlogDetail;