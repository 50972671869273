import React, { useContext, useEffect, useRef, useState } from "react";
import "./Feedback.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { AuthContext } from "../../authContext/authContext";
import { RxCross2 } from "react-icons/rx";

const Feedback = ({ setShowOverlay, onFeedbackSuccess }) => {
  const { authData } = useContext(AuthContext);
  const [data, setData] = useState({});
  const typeRef = useRef();
  const descriptionRef = useRef();
  const token = localStorage.getItem('authToken');
  const [isSend, setIsSend] = useState(false);

  useEffect(() => {
    if (authData) {
      setData(authData);
    }
  }, [authData]);  

  useEffect(() => {
    if (!token) {
      window.location.href = "/login";
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSend(true);
    const name = data?.username || "Anonymous";
    const userId = data?._id || "Unknown";
    const contactNumber = data?.phone || "Unknown";
    const type = typeRef.current.value;
    const description = descriptionRef.current.value;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NODE_BACKEND_URL}/api/feedback/save`, {
          name,
          userId,
          contactNumber,
          type,
          description
        }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
      if (response.data.success) {
        onFeedbackSuccess();
        setIsSend(false);
      } else {
        setIsSend(false);
        toast.error('Failed to submit feedback.');
      }
    } catch (error) {
      setIsSend(false);
      toast.error('Error submitting feedback. Please try again.');
    }
  };

  return (
    <>
      <div className="cross-feedback-div">
        <RxCross2 size={25} onClick={() => setShowOverlay(false)} className="cross-feedback-icon"/>
      </div>
      <div className="feedback-page">
        <h1>Submit Your Feedback</h1>
        <form className="feedback-form" onSubmit={handleSubmit}>
          <label>
            <span className="required-star">*</span>Type of Feedback:
            <select name="type" ref={typeRef} required>
              <option value="Endorsement">Endorsement</option>
              <option value="Feature Request">Feature Request</option>
              <option value="Bug Report">Bug Report</option>
              <option value="Others">Others</option>
            </select>
          </label>
          <label>
            <span className="required-star">*</span>Description:
            <textarea
              name="description"
              ref={descriptionRef}
              placeholder="Describe your feedback"
              required
            />
          </label>
          <button 
            type="submit" 
            className={`submit-btn ${isSend ? "sending" : ""} ${isSend ? "gray-btn" : ""}`} 
            disabled={isSend}
          >
            {isSend ? "Sending..." : "Submit"}
          </button>
        </form>
      </div>
    </>
  );
};

export default Feedback;
