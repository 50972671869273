import React, { useEffect, useRef, useState } from "react";
import { BiSolidOffer } from "react-icons/bi";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import "./Coupons.css";
import AllCoupons from "../../components/AllCoupons/AllCoupons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

const Coupons = () => {
  const adminToken = localStorage.getItem("adminToken");
  const [copy, setCopy] = useState(false);
  const [coupon, setCoupon] = useState("");
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  const discountref = useRef();
  const expirationDateRef = useRef();
  const maxUsageRef = useRef();

    useEffect(() => {
      if (!adminToken) {
        window.location.href = "/admin-login";
      }
    }, []);

  const copyToClipboard = (coupon) => {
    navigator.clipboard.writeText(coupon);
    handleCopy();
  };

  const handlerCouponCode = async () => {
    const discount = discountref.current.value;
    let expirationDate = expirationDateRef.current.value;
    const maxUsage = maxUsageRef.current.value;

    if (expirationDate) {
      const dateObj = new Date(expirationDate);
      dateObj.setHours(0, 0, 0, 0);
      expirationDate = dateObj.toISOString();
    }

    try {
      const response = await axios.post(
        `${node_backend}/api/coupons/generate-coupon`,
        {
          discount,
          expirationDate,
          maxUsage,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${adminToken}`,
          },
        }
      );
      if (response.data.success) {
        setCoupon(response.data.coupon.code);
        toast.success("Coupon generated successfully!");
      }
      console.log(response.data);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };

  return (
    <div className="coupons-container">
      <ToastContainer />
      {/* top section */}
      <div className="coupons-top">
        <h1>Get Coupons</h1>
        <div className="coupon-form">
          <div className="form-field">
            <label htmlFor="discount"><span className="required-red">*</span>Discount %</label>
            <input
              type="text"
              ref={discountref}
              id="discount"
              placeholder="eg 10, 20, etc.."
            />
          </div>
          <div className="form-field">
            <label htmlFor="expirationDate">Expiration Date</label>
            <input type="date" ref={expirationDateRef} id="expirationDate" />
          </div>
          <div className="form-field">
            <label htmlFor="maxUsage">Max Usage</label>
            <input
              type="text"
              ref={maxUsageRef}
              id="maxUsage"
              placeholder="Enter Max Usage"
            />
          </div>
          <div className="form-field">
            <label htmlFor="maxUsage">Get Coupon</label>
            <button className="btn-generate" onClick={handlerCouponCode}>
              Generate
            </button>
          </div>

          {coupon && (
            <div className="coupon-code">
              <div className="coupon-p-tag">
                <div>
                  <span className="coupon-span">Code:</span> {coupon}{" "}
                </div>
                <BiSolidOffer className="coupon-icon" size={"25px"} />
                <button className="button-50" onClick={() => {
                  copyToClipboard(coupon);
                }}>
                  {copy ? (
                    <div className="coupon-copy">
                      <FaCheck />
                      <span>Copied</span>
                    </div>
                  ) : (
                    <div className="coupon-copy">
                      <FaRegCopy />
                      <span>Copy</span>
                    </div>
                  )}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* bottom section */}
      <div className="coupons-bottom">
        <AllCoupons />
      </div>
    </div>
  );
};

export default Coupons;
