import React, { useState, useEffect, useContext, useRef } from 'react';
import axios from 'axios';
import { AuthContext } from '../../authContext/authContext';
import './ETFDetailsOverlay.css';

const ETFDetailsOverlay = ({ symbol, orderType: initialOrderType = 'buy',qty:initialOrderqty = '2', onClose }) => {
  const [etf, setEtf] = useState(null);
  const [orderType, setOrderType] = useState(initialOrderType);
  const [orderMode, setOrderMode] = useState('market'); // market or limit
  const [quantity, setQuantity] = useState(initialOrderqty);
  const [orderPrice, setOrderPrice] = useState(''); // Used for limit orders
  const [showConfirm, setShowConfirm] = useState(false);
  const [orderDetails, setOrderDetails] = useState({});
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;

  const { authData } = useContext(AuthContext);
  const formRef = useRef(null);

  useEffect(() => {

    const fetchETFDetails = async () => {
      try {
        const response = await axios.get(`${node_backend}/api/etf/etfs/${symbol}/details`);
        setEtf(response.data);
      } catch (error) {
        console.error("Error fetching the ETF details!", error);
      }
    };

    fetchETFDetails();
  }, [node_backend, symbol]);

  const handleOrder = () => {
    const price = orderMode === 'limit' ? parseFloat(orderPrice) : etf.price;

    if (authData && authData.email && quantity > 0 && price > 0) {
      const totalPrice = price * quantity;
      setOrderDetails({
        symbol,
        orderType,
        quantity,
        price,
        totalPrice,
      });
      setShowConfirm(true);
    } else {
      alert("Please provide valid quantity and price for your order.");
    }
  };
  const confirmOrder = async () => {
    setShowConfirm(false);
    try {
      // Retrieve token from localStorage
      const token = localStorage.getItem('authToken');
      if (!token) {
        alert('Authentication token not found. Please log in again.');
        return;
      }
  
      // Send the order confirmation request
      await axios.post(
        `${python_backend}/api/orders/place`,
        {
          email: authData.email,
          symbol,
          orderType,
          quantity,
          price: orderDetails.price,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Using token from localStorage
            'Content-Type': 'application/json', // Explicit Content-Type
          },
        }
      );
  
      // Notify user and reload
      alert(`${orderType.toUpperCase()} order placed for ${quantity} shares of ${symbol}`);
      window.location.reload();
    } catch (error) {
      console.error(`Error placing the ${orderType} order!`, error);
      alert(`Failed to place ${orderType} order. Please try again.`);
    }
  };
  const handleQuantityChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setQuantity(value);
    }
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (!isNaN(value) && Number(value) >= 0) {
      setOrderPrice(value);
    }
  };

  if (!etf) {
    return <div className="loading">Loading ETF details...</div>;
  }

  return (
    <div className="etf-details-overlay">
      <div className="order-wrapper">
        {/* <button className="close-btn" onClick={onClose}>Close</button> */}
        <div className="order-heading">
          <div className="order-heading-wrapper">
            <div className="order-heading-left">
              <div className="order-symbol-name">{etf.symbol}</div>
              <div className={`order-price ${etf.change >= 0 ? 'positive' : 'negative'}`}>
                ₹{etf.price}
              </div>
              <div className="order-price-change">
                <div>{etf.change > 0 ? '+' : ''}{etf.change.toFixed(2)}({etf.changePercent.toFixed(2)}%)</div>
              </div>
            </div>
          </div>
        </div>

        <div className="order-body">
          <div className="order-section-wrapper">
            <label>Order Type:</label>
            <select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
              <option value="buy">Buy</option>
              <option value="sell">Sell</option>
            </select>

            <label>Order Mode:</label>
            <select value={orderMode} onChange={(e) => setOrderMode(e.target.value)}>
              <option value="market">Market</option>
              <option value="limit">Limit</option>
            </select>

            {orderMode === 'limit' && (
              <div>
                <label>Limit Price:</label>
                <input
                  type="number"
                  value={orderPrice}
                  onChange={handlePriceChange}
                  min="0"
                />
              </div>
            )}

            <label>Quantity:</label>
            <input
              type="number"
              value={quantity}
              onChange={handleQuantityChange}
              min="0"
            />

            <button
              onClick={handleOrder}
              className={`${orderType === 'buy' ? 'buy-button' : 'sell-button'}`}
            >
              {orderType.toUpperCase()} ETF
            </button>
            <button className="close-button-end" onClick={onClose}>Cancel</button>
          </div>
        </div>

        {showConfirm && (
          <div className="confirmation-dialog">
            <h2>Confirm Order</h2>
            <p><strong>Order Type:</strong> {orderDetails.orderType.toUpperCase()}</p>
            <p><strong>Order Mode:</strong> {orderMode.toUpperCase()}</p>
            <p><strong>Symbol:</strong> {orderDetails.symbol}</p>
            <p><strong>Quantity:</strong> {orderDetails.quantity}</p>
            <p><strong>Price:</strong> ₹{orderDetails.price.toFixed(2)}</p>
            <p><strong>Total Price:</strong> ₹{orderDetails.totalPrice.toFixed(2)}</p>
            <button onClick={confirmOrder} className="confirm-btn">Confirm Order</button>
            <button onClick={() => setShowConfirm(false)} className="cancel-btn">Cancel</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ETFDetailsOverlay;
