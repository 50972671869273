import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './RunCodeOverlay.css';
import { useNavigate } from 'react-router-dom';

const RunCodeOverlay = ({ onClose }) => {
  const [usersToRun, setUsersToRun] = useState([]);
  const [scheduleTime, setScheduleTime] = useState('');  // Common field for time
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;
  const navigate = useNavigate();

  // on mount this check for the admintoken
  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (!token) {
      navigate('/admin-login');
    }
  }, []);

  useEffect(() => {
    const fetchRunUsers = async () => {
      const token = localStorage.getItem('adminToken');
      try {
        const response = await axios.get(`${node_backend}/admin/run-users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        console.log(response.data);
        if (response.data.success) {
          // If needed, format the times in HH:MM:SS format
          const usersWithFormattedTimes = response.data.users.map(user => ({
            ...user,
            time: user.time.includes(":") && user.time.split(":").length === 2
              ? `${user.time}:00`  // Ensure HH:MM gets padded with :00 to form HH:MM:SS
              : user.time
          }));
          setUsersToRun(usersWithFormattedTimes);
        } else {
          alert('Failed to fetch users to run');
        }
      } catch (error) {
        console.error('Error fetching run users:', error);
        alert('Error fetching users to run');
      }
    };

    fetchRunUsers();
  }, []);

  const handleTimeChange = (e) => {
    setScheduleTime(`${e.target.value}:00`); // Format time as HH:MM:SS when changed
  };

  const startRun = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(
        `${python_backend_server}/admin/start-runall`,
        { isRunning: true, time: scheduleTime }, // Send the time to the backend
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      if (response.data.success) {
        alert('Run started successfully');
      } else {
        alert(`Failed to start run: ${response.data.message}`);
      }
    } catch (error) {
      console.error('Error starting run:', error.response?.data?.message || error.message);
      alert('Error starting run');
    }
  };
  
  const stopRun = async () => {
    const token = localStorage.getItem('authToken');
    try {
      const response = await axios.post(
        `${python_backend_server}/admin/stop-runall`,
        { isRunning: false },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.data.success) {
        alert('Run stopped successfully');
      } else {
        alert('Failed to stop run');
      }
    } catch (error) {
      console.error('Error stopping run:', error);
      alert('Error stopping run');
    }
  };

  return (
    <div className="run-code-overlay">
      <div className="run-code-modal">
        <button onClick={onClose} className="close-button">X</button>
        <h2 className="mb-4">Users to Run Code</h2>
        <div className="table-responsive">
          {usersToRun.length > 0 ? (
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>User ID</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                {usersToRun.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>{user._id}</td>
                    <td>{user.username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p className="text-center">No users available to run code.</p>
          )}
        </div>

        {/* Time input for setting the schedule time */}
        <div className="mb-3">
          <label htmlFor="scheduleTime">Schedule Time</label>
          <input
            type="time"
            id="scheduleTime"
            value={scheduleTime.substring(0, 5)}  // Display only HH:MM
            onChange={handleTimeChange}  // Update the time
            className="form-control"
          />
        </div>

        {/* Buttons to start and stop the run */}
        <button
          onClick={startRun}
          className="btn btn-success"
        >
          Start All
        </button>
        <button
          onClick={stopRun}
          className="btn btn-danger"
        >
          Stop All
        </button>
      </div>
    </div>
  );
};

export default RunCodeOverlay;
