import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AdminPage.css';
import { useNavigate } from 'react-router-dom'; // Import Link and useNavigate from react-router-dom
import RunCodeOverlay from '../RunCodeOverlay/RunCodeOverlay';
import { toast } from 'react-toastify';
const AdminPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [users, setUsers] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [showRunCodeOverlay, setShowRunCodeOverlay] = useState(false); // State to control the overlay
  const [isRunning, setIsRunning] = useState(false); // New state for "isRunning"
  const [isLoggedin, setIsLoggedin] = useState(false); 
  const navigate = useNavigate(); // Initialize useNavigate
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const python_backend = process.env.REACT_APP_PYTHON_BACKEND_URL;
  const token = localStorage.getItem("adminToken");

  console.log("users",users)
  const node_backend_server = process.env.REACT_APP_NODE_BACKEND_URL_SERVER;
  const python_backend_server = process.env.REACT_APP_PYTHON_BACKEND_URL_SERVER;

  // Effect to handle token expiry
  useEffect(() => {
    if (token) {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      const expiryTime = decodedToken.exp * 1000;
      const currentTime = new Date().getTime();

      if (currentTime > expiryTime) {
        localStorage.removeItem("adminToken");
        console.log("Token has expired and has been cleared.");
      }
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("adminToken");
    if (token) {
      setIsAuthenticated(true);
      setIsLoggedin(true);
    } else {
      navigate('/admin-login');
    }
  }, []);

  useEffect(() => {
    const fetchRunStatus = async () => {
      const token = localStorage.getItem('adminToken');
      if (isLoggedin) {
        try {
          const response = await axios.get(`${node_backend}/admin/check-isrunning`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          if (response.data.isRunning !== undefined) {
            setIsRunning(response.data.isRunning);
          } else {

            console.error('Unexpected response structure:', response);
          }
        } catch (error) {
          console.error('Error fetching run status:', error);
          alert('Failed to fetch run status');
        }
      };
    }

    const fetchUsers = async () => {
      const token = localStorage.getItem('adminToken');
      try {
        const response = await axios.get(`${node_backend}/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.success) {
          setUsers(response.data.users.map(user => ({
            ...user,
            time: user.start_time || '12:00',
          })));
        } else {
          alert('Failed to fetch users');
        }
      } catch (error) {
        console.error('Error fetching users:', error);
        alert('Failed to fetch users');
      }
    };

    if (isAuthenticated) {
      fetchUsers();
    }
    fetchRunStatus();
    const intervalId = setInterval(fetchRunStatus, 5000); // Repeat every second

    // Cleanup the interval when the component unmounts or dependencies change
    return () => clearInterval(intervalId);
  }, [isAuthenticated]);


  // Handle payments page redirection with auth check
  const navigateToPayments = () => {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      alert('You are not authorized to view this page. Please log in.');
      navigate('/admin');
    } else {
      navigate('/admin/payments');
    }
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const handlePlanChange = (userId, newPlan) => {
    const token = localStorage.getItem('adminToken');
    axios.post(
      `${node_backend}/admin/update-plan`,
      { userId, newPlan },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, plan: newPlan } : user
          )
        );
      } else {
        alert('Failed to update plan');
      }
    }).catch((error) => {
      console.error('Error updating plan:', error);
      alert('Error updating plan');
    });
  };
  const handleAutomationToggle = async (userId, automated, scheduleTime) => {
    const token = localStorage.getItem('adminToken');
    try {
      const response = await axios.post(
        `${node_backend}/admin/toggle-mode`,
        { userId, automated: !automated, scheduleTime },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.success) {
        const newAutomatedState = !automated;
        const pythonEndpoint = newAutomatedState
          ? `${python_backend_server}/admin/strategy/start`
          : `${python_backend_server}/admin/strategy/stop`;

        try {
          await axios.post(
            pythonEndpoint,
            { userId, scheduleTime },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );
          setUsers((prevUsers) =>
            prevUsers.map((user) =>
              user._id === userId
                ? { ...user, automated: newAutomatedState, time: scheduleTime }
                : user
            )
          );
        } catch {
          console.error('Failed to start/stop the strategy');
          alert('Failed to start/stop the strategy');
        }
      } else {
        alert('Failed to update automation status');
      }
    } catch (error) {
      console.error('Error updating automation status:', error);
      alert('Error updating automation status');
    }
  };

  const handleRunCodeToggle = async (userId) => {
    const token = localStorage.getItem('adminToken');

    try {
      const response = await axios.post(
        `${node_backend}/admin/toggle-runcode`,
        { userId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      // Directly update the users state with the updated runcode value
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, runcode: response.data.user.runcode } : user
        )
      );

    } catch (error) {
      console.error('Error updating runcode status:', error);
    }
  };
  const handleTimeChange = (userId, newTime) => {
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user._id === userId ? { ...user, time: newTime } : user
      )
    );
  };

  // Handle updating days remaining
  const handleDaysRemainingChange = (userId, daysRemaining) => {
    const token = localStorage.getItem('adminToken');
    axios.post(
      `${node_backend}/admin/update-days-remaining`,
      { userId, daysRemaining },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ).then((response) => {
      if (response.data.success) {
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user._id === userId ? { ...user, daysRemaining } : user
          )
        );
      } else {
        alert('Failed to update days remaining');
      }
    }).catch((error) => {
      console.error('Error updating days remaining:', error);
      alert('Error updating days remaining');
    });
  };

  const handlePaymentsPage = () => {
    const token = localStorage.getItem('adminToken');
    if(!token) {
      toast.error("Please login first.");
    }else {
      navigate('/admin-payments');
    }
    
  }

  const handleFeedbackNavigation = () => {
    navigate("/feedback-response");
  };

  const handleBlogsNavigation = () => {
    navigate("/post-blogs");
  };

  const handleShowBlogsNavigation = () => {
    navigate("/get-blogs");
  };

  const handleCouponsNavigation = () => {
    navigate("/coupons");
  };

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    navigate('/admin-login');
  };

  // delete
  const handleDeleteUser = async (userId, type) => {
    const token = localStorage.getItem('adminToken');
      try{ 
        const response = await axios.post(
            `${node_backend}/api/creds/delete-creds`,
            { userId, type },
            {
                headers: {
                  "authorization": `Bearer ${token}`,
                  "Content-Type": "application/json",
                },
            }
        );
        if (response.data.success) {
          setUsers((prevUsers) =>
              prevUsers.map((user) =>
                  user._id === userId ? { ...user, creds: false } : user
              )
          );
          toast.success("Credentials deleted successfully");
        } else {
            toast.error(response.data.message || "Failed to delete credentials");
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        toast.error("Error deleting user. Please try again.");
      }
  }

  return (
    <div className="container mt-5">
      <div className='logout-btn-admin'>
        <button onClick={handleLogout} className="btn btn-secondary me-2 mb-2">
          Log out
        </button>
      </div>
      {isAuthenticated ? (
        <div>
          {/* <div className="text-right mb-4">
            <button onClick={navigateToPayments} className="btn btn-secondary">
              Go to Payments
            </button>
          </div> */}
          <div className="text-right mb-4">
            <button onClick={handlePaymentsPage} className="btn btn-secondary me-2">
              View Payments
            </button>
            <button onClick={() => setShowRunCodeOverlay(true)} className="btn btn-secondary me-2">
              View Run Code
            </button>
            <button onClick={handleFeedbackNavigation} className="btn btn-secondary me-2">
              Feedback response
            </button>
            <button onClick={handleBlogsNavigation} className="btn btn-secondary me-2">
              Add Blogs
            </button>
            <button onClick={handleShowBlogsNavigation} className="btn btn-secondary me-2">
              Show Blogs
            </button>
            <button onClick={handleCouponsNavigation} className="btn btn-secondary me-2">
              Get Coupons
            </button>
            <div>
              {isRunning ? "Running" : "Not Running"}
            </div>
          </div>
          <h2 className="mb-4 text-center">All Users</h2>
          <div className="table-responsive">
            <table className="table table-striped table-hover table-bordered">
              <thead className="thead-dark">
                <tr>
                  <th>#</th>
                  <th>User ID</th> {/* New column for User ID */}
                  {/* <th onClick={() => handleSort('email')}>Email</th> */}
                  <th onClick={() => handleSort('username')}>Username</th>
                  <th onClick={() => handleSort('phone')}>Phone</th>
                  <th>Credential Type</th> {/* New column for credType */}
                  <th>UID</th> {/* Displaying UID */}

                  {/* <th onClick={() => handleSort('plan')}>Plan</th> */}
                  <th onClick={() => handleSort('createdAt')}>Created At</th>
                  {/* <th onClick={() => handleSort('lastOnline')}>Last Online</th> */}
                  <th onClick={() => handleSort('creds')}>Credentials Status</th>
                  <th>Days Remaining</th> {/* New Column for Days Remaining */}
                  <th>Automated</th>
                  <th>Run All</th>
                  <th>Schedule Time</th>
                  <th>Update Plan</th>
                  <th>Delete Credential</th>
                </tr>
              </thead>
              <tbody>
                {sortedUsers.map((user, index) => (
                  <tr key={user._id}>
                    <td>{index + 1}</td>
                    <td>{user._id}</td> {/* Displaying the User ID */}
                    {/* <td>{user.email}</td> */}
                    <td>{user.username}</td>
                    <td>{user.phone}</td>
                    <td>{user.credType}</td> {/* Displaying credType */}
                    {/* Displaying credentials based on credType */}
                    <td>{user.credentials?.uid || 'N/A'}</td>

                    {/* <td>{user.plan}</td> */}
                    <td>{new Date(user.createdAt).toLocaleString()}</td>
                    {/* <td>{new Date(user.lastOnline).toLocaleString()}</td> */}
                    <td>{user.creds ? 'Yes' : 'No'}</td>
                    <td>
                      <input
                        type="number"
                        value={user.daysRemaining || 0}
                        onChange={(e) =>
                          handleDaysRemainingChange(user._id, parseInt(e.target.value, 10))
                        }
                        className="form-control"
                      />
                    </td>
                    <td>
                      <button
                        className={`btn btn-sm ${user.automated ? 'btn-success' : 'btn-secondary'}`}
                        onClick={() => handleAutomationToggle(user._id, user.automated, user.time)}
                      >
                        {user.automated ? 'Running' : 'Start'}
                      </button>



                    </td>
                    <td>
                      <button
                        className={`btn btn-sm ${user.runcode ? 'btn-success' : 'btn-secondary'}`}
                        onClick={() => handleRunCodeToggle(user._id)}
                        disabled={user.automated || isRunning}
                      >
                        {user.runcode ? 'Remove' : 'Add'}
                      </button>
                    </td>
                    <td>
                      <input
                        type="time"
                        value={user.time}
                        onChange={(e) => handleTimeChange(user._id, e.target.value)}
                        className="form-control"
                        disabled={user.automated}
                      />
                    </td>
                    <td>
                      <select
                        value={user.plan}
                        onChange={(e) => handlePlanChange(user._id, e.target.value)}
                        className="form-control"
                      >
                        <option value="Gold">Gold</option>
                        <option value="Silver">Silver</option>
                        <option value="Free">Free</option>
                      </select>
                    </td>
                    <td>
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={() => handleDeleteUser(user._id, user.credType)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>

            </table>
          </div>
          {
            showRunCodeOverlay && (
              <div className='runcode-overlay'>
                <div className='runcode-modal'>
                  <button onClick={() => setShowRunCodeOverlay(false)} className="close-button">X</button>
                  <RunCodeOverlay onClose={() => setShowRunCodeOverlay(false)} /> {/* Pass the onClose prop */}
                </div>
              </div>
            )
          }
        </div>
      ) : (
        "Not authenticated"
      )}
    </div>
  );
};

export default AdminPage;
