import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
// import LandingPage from './pages/LandingPage/LandingPage';
import LandingPage from './pages/LandingPage/LandingPage';
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import { AuthProvider } from './authContext/authContext';
import Layout from './components/Layout/Layout';
import Products from './pages/ProductPage/ProductPage';
import HomePage from './pages/HomePage/HomePage';
import Strategy from './pages/Strategy/StrategyPage';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ETFDetails from './pages/ETFDetails/ETFDetails'; // Added this import
import ProfilePage from './pages/ProfilePage/ProfilePage'; // Add this import
import AdminPage from './pages/AdminPage/AdminPage';
import ContactUs from './pages/ContactUs/ContactUs';
import Referrals from './pages/ReferralPage/ReferralsPage';
import OrdersPage from './pages/OrdersPage/OrdersPage';
import PortfolioPage from './pages/PortfolioPage/PortfolioPage';
import FaqPage from './pages/FaqPage/FaqPage';
// import AdminPaymentsPage from './pages/AdminPaymentsPage/AdminPaymentsPage';
// import AdminPaymentsPaimport age from './pages/AdminPaymentsPage/AdminPaymentsPage'; // Import the new page
// import AdminPaymentsPage  from './pages/AdminPaymentsPage/AdminPaymentsPage'
// import { Navigate } from 'react-router-dom';
import Feedback from './pages/Feedback/Feedback';
import Modelportfolio from './pages/Modelportfoliopage/Modelportfolio';
import Blogs from './pages/blogs/Blogs';
import AdminUserInfo from './pages/AdminUserInfo/AdminUserInfo';
import FeedbackResponsePage from './pages/FeedbackResponsePage/FeedbackResponsePage';
import AdminLogin from './pages/AdminLoginPage/AdminLogin';
import PaymentPage from './pages/PaymentPage/PaymentPage';
import BlogList from './pages/DisplayBlogs/BlogList';
import BlogDetail from './pages/BlogDetail/BlogDetail';
import Coupons from './pages/Coupons/Coupons';
import BlogCardCarousel from './components/NewBlog/BlogCardCarousel';
import { toast, ToastContainer } from 'react-toastify';

// Secure route wrapper for authentication check
const AdminRoute = ({ element }) => {
  const token = localStorage.getItem('authToken');
  return token ? element : <Navigate to="/admin" />;
};

function App() {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleCloseOverlay = (event) => {
    if (event.target === event.currentTarget) {
      setShowOverlay(false);
    }
  };

  const handleFeedbackSuccess = () => {
    toast.success("Feedback submitted successfully!", { autoClose: 2000 });
    setShowOverlay(false);
  };

  return (
    <AuthProvider>
      <ToastContainer />
      <Router>
        <AppRoutes showOverlay={showOverlay} setShowOverlay={setShowOverlay} handleCloseOverlay={handleCloseOverlay} handleFeedbackSuccess={handleFeedbackSuccess} />
      </Router>
    </AuthProvider>
  );
}

function AppRoutes({ showOverlay, setShowOverlay, handleCloseOverlay, handleFeedbackSuccess }) {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/feedback') {
      setShowOverlay(true);
    }
  }, [location.pathname, setShowOverlay]);

  return (
    <div className="App">
      <Navbar />
      {showOverlay && (
        <div className="overlay" onClick={handleCloseOverlay}>
          <div className="overlay-content" onClick={(e) => e.stopPropagation()}>
            <Feedback setShowOverlay={setShowOverlay} onFeedbackSuccess={handleFeedbackSuccess} />
          </div>
        </div>
      )}
      <Routes>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/admin-user-info" element={<AdminUserInfo />} />
        <Route path="/admin-payments" element={<PaymentPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/products" element={<Products />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/" element={<LandingPage />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/feedback-response" element={<FeedbackResponsePage />} />
        <Route path="/post-blogs" element={<Blogs />} />
        <Route path="/get-blogs" element={<BlogList />} />
        <Route path="/blog/:id" element={<BlogDetail />} />
        <Route path="/coupons" element={<Coupons />} />
        <Route path="/feedback" element={<Navigate to="/" />} />
        <Route
          path="*"
          element={
            <Layout>
              <Routes>
                <Route path="/orders" element={<OrdersPage />} />
                <Route path="/dashboard" element={<HomePage />} />
                <Route path="/strategy" element={<Strategy />} />
                <Route path="/etf/:symbol" element={<ETFDetails />} />
                <Route path="/referrals" element={<Referrals />} />
                <Route path="/profile" element={<ProfilePage />} />
                <Route path="/portfolio" element={<PortfolioPage />} />
                <Route path="/model-portfolio" element={<Modelportfolio />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
