import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AuthContext } from "../../authContext/authContext";
import "./ReferralsPage.css";
import { RxCross2 } from "react-icons/rx";

// Separate calculator component
const ReferralEarningsCalculator = () => {
  // const [hasPurchased, setHasPurchased] = useState();
  const calculateEarnings = () => {
    const silverMonthly =
      parseInt(document.getElementById("silverMonthly").value) || 0;
    const silverYearly =
      parseInt(document.getElementById("silverYearly").value) || 0;
    const goldMonthly =
      parseInt(document.getElementById("goldMonthly").value) || 0;
    const goldYearly =
      parseInt(document.getElementById("goldYearly").value) || 0;

    // Subscription costs
    const silverMonthlyCost = 199;
    const silverYearlyCost = 1999 / 12;
    const goldMonthlyCost = 299;
    const goldYearlyCost = 2999 / 12;

    // Calculate total number of referrals
    let totalReferrals =
      silverMonthly + silverYearly + goldMonthly + goldYearly;

    // Determine commission percentage based on referral count
    let commissionPercentage = 0;
    if (totalReferrals <= 10) {
      commissionPercentage = 0.2; // 20% for 0-10 users
    } else if (totalReferrals <= 50) {
      commissionPercentage = 0.3; // 30% for 11-50 users
    } else if (totalReferrals <= 100) {
      commissionPercentage = 0.4; // 40% for 51-100 users
    } else {
      commissionPercentage = 0.5; // 50% for over 100 users
    }

    // Calculate earnings based on referrals and commission percentage
    let earnings =
      (silverMonthly * silverMonthlyCost +
        silverYearly * silverYearlyCost +
        goldMonthly * goldMonthlyCost +
        goldYearly * goldYearlyCost) *
      commissionPercentage;

    document.getElementById(
      "result"
    ).innerHTML = `You can earn <strong>₹${earnings.toFixed(
      0
    )}</strong> per month based on your referrals.`;

    // Calculate next tier motivation
    let nextTierReferrals = 0;
    let nextTierPercentage = 0;
    let nextTierEarnings = 0;

    if (totalReferrals <= 10) {
      nextTierReferrals = 11 - totalReferrals;
      nextTierPercentage = 0.3; // Next tier percentage
    } else if (totalReferrals <= 50) {
      nextTierReferrals = 51 - totalReferrals;
      nextTierPercentage = 0.4;
    } else if (totalReferrals <= 100) {
      nextTierReferrals = 101 - totalReferrals;
      nextTierPercentage = 0.5;
    }

    // Calculate possible earnings if moving to the next tier with Gold Monthly users
    const possibleNewUsersEarnings =
      nextTierReferrals * goldMonthlyCost * nextTierPercentage;

    if (nextTierReferrals > 0) {
      nextTierEarnings =
        (silverMonthly * silverMonthlyCost +
          silverYearly * silverYearlyCost +
          goldMonthly * goldMonthlyCost +
          goldYearly * goldYearlyCost +
          possibleNewUsersEarnings) *
        nextTierPercentage;

      document.getElementById(
        "motivation"
      ).innerHTML = `You are in the <strong>${
        commissionPercentage * 100
      }%</strong> commission tier earning <strong>₹${earnings.toFixed(
        0
      )}</strong> per month.<br>
                With just <strong>${nextTierReferrals}</strong> more users, you will move to the <strong>${
        nextTierPercentage * 100
      }%</strong> commission tier and earn up to <strong>₹${nextTierEarnings.toFixed(
        0
      )}</strong> per month!`;
    } else {
      document.getElementById("motivation").innerHTML =
        "Great job! You've reached the maximum commission tier!";
    }
  };

  // const handleHasPurchased = async (userId) => {
  //   try{
  //     const response = await axios.get(`${process.env.REACT_APP_NODE_BACKEND_URL}/api/referrals/has-purchased`,{
  //       userId,
  //     }, {
  //       headers: {
  //         "Authorization": localStorage.getItem("authToken"),
  //       },
  //     });
  //     console.log(response.data);
  //   } catch (error) {
  //     console.error("Error fetching hasPurchased:", error);
  //   }
  // };

  // useEffect(() => {
  //   handleHasPurchased();
  // }, []);

  return (
    <div className="calculator-section">
      <h2>Referral Earnings Calculator</h2>
      <p>
        Just enter the likely number of your referrals and check your potential
        earnings!
      </p>
      <input
        type="number"
        id="silverMonthly"
        placeholder="Silver Tier - Users paying Monthly"
      />
      <input
        type="number"
        id="silverYearly"
        placeholder="Silver Tier - Users paying Yearly"
      />
      <input
        type="number"
        id="goldMonthly"
        placeholder="Gold Tier - Users paying Monthly"
      />
      <input
        type="number"
        id="goldYearly"
        placeholder="Gold Tier - Users paying Yearly"
      />
      <button onClick={calculateEarnings}>Calculate</button>
      <p id="result"></p>
      <p id="motivation"></p>
    </div>
  );
};

const Referrals = () => {
  const { authData } = useContext(AuthContext);
  const [referralLink, setReferralLink] = useState("");
  const [referralEarnings, setReferralEarnings] = useState(null);
  const [showCalculator, setShowCalculator] = useState(false);
  const [showWithdrawalForm, setShowWithdrawalForm] = useState(false); // To show/hide withdrawal form
  const [showOverlay, setShowOverlay] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({
    upiId: "",
    bankAccount: "",
    ifscCode: "",
  });
  const currentUrl = window.location.origin;
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

    useEffect(() => {
      const token = localStorage.getItem('authToken');
      if (!token) {
        window.location.href = "/login";
      }
    }, []);

  useEffect(() => {
    const fetchReferralLink = async () => {
      if (authData) {
        if (authData?.referralCode) {
          setReferralLink(`${currentUrl}/login?ref=${authData.referralCode}`);
        } else {
          try {
            const response = await axios.post(
              `${node_backend}/api/referrals/generate-referral-code`,
              {
                userId: authData._id,
              }
            );
            const code = response.data.referralCode;
            setReferralLink(`${currentUrl}/register?ref=${code}`);
          } catch (error) {
            console.error("Error fetching referral link:", error);
          }
        }
      }
    };

    const fetchReferralEarnings = async () => {
      if (authData?.referralEarnings) {
        try {
          setReferralEarnings(authData.referralEarnings || 0);
        } catch (error) {
          console.error("Error fetching referral earnings:", error);
        }
      }
    };

    fetchReferralLink();
    fetchReferralEarnings();
  }, [authData, currentUrl, node_backend]);

  const handleWithdraw = async () => {
    try {
      const response = await axios.post(
        `${node_backend}/api/payments/withdraw`,
        {
          userId: authData._id,
          paymentDetails,
          amount: referralEarnings,
        }
      );
      alert("Withdrawal request submitted successfully!");
    } catch (error) {
      console.error("Error submitting withdrawal request:", error);
      alert("Failed to submit withdrawal request.");
    }
  };

  const handlePaymentDetailsChange = (e) => {
    setPaymentDetails({
      ...paymentDetails,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="referrals-container">
      <h1>Spread the Word and Earn Big!</h1>
      <p>
        Join us in spreading this amazing technology with the world! For each
        referral you bring to us, you'll earn a handsome fee based on the number
        of successful referrals.
      </p>
      <button
        className="referral-button"
        onClick={() => {
          setShowCalculator(true);
          setShowOverlay(true);
        }}
      >
        🧮 Check Your Earnings Potential!
      </button>

      {showCalculator && showOverlay && (
        <div className="overlay-ref">
          <div className="overlay-content-ref">
            <div className="calculator-popup">
              <div className="referral-calculator-close">
                <RxCross2
                  onClick={() => setShowCalculator(false)}
                  className="cross-close"
                />
              </div>
              <ReferralEarningsCalculator />
            </div>
          </div>
        </div>
      )}

      {/* Referral Earnings Section */}
      <div className="referral-earnings">
        <h2>Your Referral Earnings</h2>
        <p>
          {referralEarnings !== null
            ? `₹${referralEarnings.toFixed(2)}`
            : "No earnings yet"}
        </p>
        <button
          className="withdrawal-btn"
          onClick={() => setShowWithdrawalForm(true)}
        >
          Withdraw
        </button>
      </div>

      {/* Withdrawal Form */}
      {showWithdrawalForm && (
        <center>
          <div className="withdrawal-form">
            <h3>Withdrawal Form</h3>
            <label>UPI ID:</label>
            <input
              type="text"
              name="upiId"
              value={paymentDetails.upiId}
              onChange={handlePaymentDetailsChange}
            />

            <label>Bank Account Number:</label>
            <input
              type="text"
              name="bankAccount"
              value={paymentDetails.bankAccount}
              onChange={handlePaymentDetailsChange}
            />

            <label>IFSC Code:</label>
            <input
              type="text"
              name="ifscCode"
              value={paymentDetails.ifscCode}
              onChange={handlePaymentDetailsChange}
            />

            <div className="withdrawal-btn-div">
              <button onClick={handleWithdraw} className="submit-btn">
                Submit
              </button>
              <button
                onClick={() => setShowWithdrawalForm(false)}
                className="submit-btn"
              >
                Close
              </button>
            </div>
          </div>
        </center>
      )}

      <div className="referral-table">
        <table>
          <thead className="table-heading">
            <tr>
              <th>Referrals</th>
              <th>Earned Percentage</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Up to 10 referrals</td>
              <td>20% of monthly fee</td>
            </tr>
            <tr>
              <td>11 - 50 referrals</td>
              <td>30% of monthly fee</td>
            </tr>
            <tr>
              <td>51 - 100 referrals</td>
              <td>40% of monthly fee</td>
            </tr>
            <tr>
              <td>More than 100 referrals</td>
              <td>50% of monthly fee</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Referral Link Section */}
      <div className="referral-link-section">
        <p>Your unique referral link:</p>
        {authData?.plan !== "Free" ? (
          <>
            <input type="text" value={referralLink} readOnly />
            <button onClick={() => navigator.clipboard.writeText(referralLink)}>
              Copy Link
            </button>
          </>
        ) : (
          <p>
            Please{" "}
            <a href="/products" className="subscribe-link">
              subscribe to a plan
            </a>{" "}
            to experience the magic first hand before referring friends and
            family.
          </p>
        )}
      </div>
    </div>
  );
};

export default Referrals;
