import { Line } from 'react-chartjs-2';
import { Chart , CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import "./AdminUserInfo.css"; 
import { useEffect, useState } from 'react';
const AdminUserInfo = () => {

  useEffect(() => {
    const adminToken = localStorage.getItem("adminToken");
    if (!adminToken) {
      window.location.href = "/admin-login";
    }
  }, []);

  
    const dummyData = [
        { date: "2024-12-20", num_registered: 50, num_gold: 10 },
        { date: "2024-12-21", num_registered: 75, num_gold: 20 },
        { date: "2024-12-22", num_registered: 100, num_gold: 25 },
        { date: "2024-12-23", num_registered: 150, num_gold: 40 },
        { date: "2024-12-24", num_registered: 200, num_gold: 50 },
        { date: "2024-12-25", num_registered: 250, num_gold: 60 },
        { date: "2024-12-26", num_registered: 300, num_gold: 80 },
        { date: "2024-12-27", num_registered: 350, num_gold: 100 },
        { date: "2025-01-04", num_registered: 800, num_gold: 240 },
        { date: "2025-01-05", num_registered: 850, num_gold: 250 },
        { date: "2025-01-06", num_registered: 900, num_gold: 270 },
        { date: "2025-01-07", num_registered: 1000, num_gold: 300 },
      ];      

    const [showRegistered, setShowRegistered] = useState(false);
    const [showGoldMembers, setShowGoldMembers] = useState(false);

    // graph
    const chartData = {
        labels: dummyData.map((data) => data.date),
        datasets: [
            showRegistered && {
                label: "Registered members",
                data: dummyData.map((data) => data.num_registered),
                borderColor: 'blue',
                backgroundColor: 'rgba(0, 0, 255, 0.2)',
                borderWidth: 2,
            },
            showGoldMembers && {
                label: "Gold Members",
                data: dummyData.map((data) => data.num_gold),
                borderColor: 'gold',
                backgroundColor: 'rgba(255, 215, 0, 0.2)',
                borderWidth: 2,
            },
        ].filter(Boolean),
    }

    const options = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top',
          },
          tooltip: {
            mode: 'index',
            intersect: false,
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Date',
            },
          },
          y: {
            title: {
              display: true,
              text: 'Number of Members',
            },
          },
        },
      };
  return (
    <>
    <div className='admin-user-info'>
        <div className='admin-user-info-title'>
            <h1>Admin User Info</h1>
        </div>
        <div className="admin-user-info-container">
            {/* toggler  */}
            <div className="checkbox-div">
                <label className="checkbox-label">
                    <input
                    checked={showRegistered}
                    onChange={() => setShowRegistered(!showRegistered)}
                    type="checkbox"
                    className="custom-checkbox"
                    />
                    Show Registered Members
                </label>
                <label className="checkbox-label">
                    <input
                    checked={showGoldMembers}
                    onChange={() => setShowGoldMembers(!showGoldMembers)}
                    type="checkbox"
                    className="custom-checkbox"
                    />
                    Show Gold Members
                </label>
            </div>
            {/* dont forget to add options  */}
            <div className='admin-user-info-chart'>
                <Line data={chartData} options={options} />
            </div>
        </div>
    </div>
    </>
  )
}

export default AdminUserInfo
