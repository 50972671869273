import axios from 'axios';
import { useEffect, useState } from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import "./graph.css";

const rupeeFormatter = (value) => {
  if (value >= 1e7) {
    return `${Math.round(value / 1e7)} Cr`;
  } else if (value >= 1e5) {
    return `${Math.round(value / 1e5)} L`;
  } else {
    return `${Math.round(value)}`; 
  }
};

export default function AumGraph() {
  const [aumData, setAumData] = useState([]);
  const [totalAum, setTotalAum] = useState(0);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

  const handleAMUGraph = async () => {
    try {
      const response = await axios.get(`${node_backend}/api/graphs/aum-data`);
      if (response.data.success) {
        setTotalAum(response.data.totalAum);

        const updatedData = response.data.data.reduce((acc, curr, index) => {
          const cumulativeInvestment = index === 0 
            ? curr.investment 
            : acc[index - 1].totalAmount + curr.investment;

          acc.push({
            _id: curr._id,
            totalAmount: cumulativeInvestment
          });

          return acc;
        }, []);

        setAumData(updatedData);
      }
    } catch (error) {
      console.error("Error fetching AUM data:", error);
    }
  };

  useEffect(() => {
    handleAMUGraph();
  }, []);

  // Custom Tooltip Component
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`Total Amount: ₹${payload[0].value.toLocaleString()}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className='graph-container'>
      <div>
        <h4 className="graph-title">Total AUM: 
          <span className="graph-heading-span">{rupeeFormatter(totalAum)}</span>
        </h4>
        <ResponsiveContainer width="100%" height={400}>
          <AreaChart data={aumData}>
            <CartesianGrid stroke="transparent" />
            <XAxis
              tick={{ display: 'none' }}
              label={{ value: '', position: 'insideBottom' }}
            />
            <YAxis tickFormatter={(tick) => `${(tick / 1e5).toFixed(0)}L`} />
            <Tooltip content={<CustomTooltip />} />
            <Area
              type="monotone"
              dataKey="totalAmount"
              stroke="#F7BB67"
              fill="url(#colorUv)"
              fillOpacity={1}
            />
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#F7BB67" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#F7BB67" stopOpacity={0} />
              </linearGradient>
            </defs>
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
