import axios from "axios";
import { useEffect, useState } from "react";
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import "./graph.css";

export default function TotalProfitsBooked() {
    const [profitsData, setProfitsData] = useState([]);
    const [totalProfitBooked, setTotalProfitBooked] = useState(0);
    const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;

    const fetchProfitsData = async () => {
        try {
            const response = await axios.get(`${node_backend}/api/graphs/profits`);
            if (response.data.success) {
                setProfitsData(response.data.data);
                setTotalProfitBooked(response.data.totalProfitBooked);
            }
        } catch (error) {
            console.error("Error fetching profits data:", error);
        }
    };

    const CustomTooltip = ({ active, payload }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Count: ${payload[0].value}`}</p>
                </div>
            );
        }
        return null;
    };

    useEffect(() => {
        fetchProfitsData();
    }, []);

    // Prepare chart data directly from profitsData, which already contains cumulative profits
    const chartDataProfits = profitsData.map(({ date, profit }) => ({
        date, // Assume date is a timestamp or formatted date string
        profit,
    }));

    // Formatter for large numbers (no decimal places)
    const rupeeFormatter = (value) => {
        if (value >= 1e7) {
            return `${Math.round(value / 1e7)} Cr`;
        } else if (value >= 1e5) {
            return `${Math.round(value / 1e5)} L`;
        } else {
            return `${Math.round(value)}`;
        }
    };

    return (
        <div className='graph-container'>
            <div>
                <h4 className="graph-title">Total Profit Booked: 
                    <span className="graph-heading-span">
                        {rupeeFormatter(totalProfitBooked)}
                    </span>
                </h4>
                
                <ResponsiveContainer width="100%" height={400}>
                    <AreaChart data={chartDataProfits}>
                        <defs>
                            <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#F7BB67" stopOpacity={0.8} />
                                <stop offset="95%" stopColor="#F7BB67" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <XAxis
                            tick={{ display: 'none' }}
                            label={{ value: '', position: 'insideBottom' }}
                        />
                        <YAxis tickFormatter={rupeeFormatter} />
                        <Tooltip content={<CustomTooltip />} />
                        <Area 
                            type="monotone"
                            dataKey="profit"
                            stroke="#F7BB67"
                            fill="url(#colorUv)"
                            fillOpacity={1}
                            strokeWidth={2}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}
