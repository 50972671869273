import React, { useEffect, useState } from "react";
import axios from "axios";
import "./AllCoupons.css";
import { toast, ToastContainer } from "react-toastify";

const AllCoupons = () => {
  const [coupons, setCoupons] = useState([]);
  const node_backend = process.env.REACT_APP_NODE_BACKEND_URL;
  const adminToken = localStorage.getItem("adminToken");

  const handleAllCoupons = async () => {
    try {
      const response = await axios.get(
        `${node_backend}/api/coupons/get-coupons`, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          },
        });
      console.log(response.data);
      if (response.data.success) {
        setCoupons(response.data.coupons);
      }
    } catch (error) {
      console.error("Error fetching coupons", error);
    }
  };

  const handleDelete = async (couponId) => {
    try {
      const response = await axios.delete(
        `${node_backend}/api/coupons/delete-coupon/${couponId}`, {
          headers: {
            Authorization: `Bearer ${adminToken}`,
          }
        });
      if (response.data.success) {
        toast.success("Coupon deleted successfully!");
        handleAllCoupons();
      }
    } catch (error) {
      console.error("Error deleting coupon", error);
    }
  };

  const copyToClipboard = (coupon) => {
    navigator.clipboard.writeText(coupon);
    toast.success("Coupon code copied to clipboard!");
  };

  useEffect(() => {
    handleAllCoupons();
  }, []);

  return (
    <>
      <ToastContainer />
      <h1 className="all-coupons-title">All Coupons</h1>
      <div className="all-coupons-container">
        {coupons.map((coupon) => (
          <div className="coupon-card" key={coupon._id}>
            <div className="coupon-details">
              <h3>Code: {coupon.code}</h3>
              <p>Discount: {coupon.discount}%</p>
              <p>
                Expiration:{" "}
                {new Date(coupon.expirationDate).toLocaleDateString()}
              </p>
              <p>Max Usage: {coupon.maxUsage}</p>
              <p>Usage Count: {coupon.usageCount}</p>
            </div>
            <button
              className="copy-button"
              onClick={() => {
                copyToClipboard(coupon.code);
              }}
            >
              Copy Code
            </button>
            <button
              className="delete-button"
              onClick={() => {
                handleDelete(coupon._id);
                handleAllCoupons();
              }}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default AllCoupons;
