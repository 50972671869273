import axios from "axios";
import "./FeedbackResponsePage.css";
import { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const FeedbackResponsePage = () => {
    const [feedback, setFeedback] = useState([]);
    const navigate = useNavigate();
    const adminToken = localStorage.getItem("adminToken");

    if(!adminToken) {
        toast.error("You are not authorized to view this page. Please log in.");
        navigate('/admin-login');
    }

    const handleFeedbackResponseData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_NODE_BACKEND_URL}/api/feedback/get`);
            if (response.data.success) {
                setFeedback(response.data.feedback);
            } else {
                toast.error("Error fetching feedback. Please try again.");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error fetching feedback. Please try again.");
        }
    };

    const handleDeleteFeedback = async (feedbackId) => {
        try {
            const response = await axios.delete(
                `${process.env.REACT_APP_NODE_BACKEND_URL}/api/feedback/delete/${feedbackId}`
            );
            if (response.data.success) {
                toast.success("Feedback deleted successfully!");
                handleFeedbackResponseData();
            } else {
                toast.error("Error deleting feedback. Please try again.");
            }
        } catch (error) {
            console.error(error);
            toast.error("Error deleting feedback. Please try again.");
        }
    };

    useEffect(() => {
        handleFeedbackResponseData();
    }, []);

    return (
        <div className="feedback-container">
            <ToastContainer />
            <h1 className="feedback-title">Feedback Response Page</h1>
            <div className="feedback-grid">
                {feedback.map((item) => (
                    <>
                        <div className="feedback-card" key={item._id}>
                            <h3 className="feedback-type">{item.type}</h3>
                            <p><strong>Name:</strong> {item.name || "N/A"}</p>
                            <p><strong>User ID:</strong> {item.userId || "N/A"}</p>
                            <p><strong>Contact:</strong> {item.contactNumber || "N/A"}</p>
                            <p><strong>Description:</strong> {item.description}</p>
                            {item.type === "Endorsement" && (
                            <p><strong>Endorsement:</strong> Yes</p> // Add Endorsement condition
                            )}
                            <p className="feedback-date">
                                <strong>Created At:</strong> {new Date(item.createdAt).toLocaleString()}
                            </p>
                            <div className="feedback-delete-icon" onClick={() => handleDeleteFeedback(item._id)}>
                                <MdDelete />
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
};

export default FeedbackResponsePage;
