import React, { useState, useEffect } from "react";
import axios from "axios";
import { Icon } from "react-icons-kit";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { eye } from "react-icons-kit/feather/eye";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Register.css";
import rightImage from "./image/etf.png";

const Register = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [emailVerified, setEmailVerified] = useState(false);
  const [loadingSendOtp, setLoadingSendOtp] = useState(false);
  const [loadingVerifyOtp, setLoadingVerifyOtp] = useState(false);
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [referralCode, setReferralCode] = useState(null); // New state for referral code

  const backendUrl = process.env.REACT_APP_NODE_BACKEND_URL; // Accessing backend URL from .env

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refCode = urlParams.get("ref");
    if (refCode) {
      setReferralCode(refCode); // Set the referral code if present in the URL
    }
  }, []);
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Function to validate email format using regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to validate phone number (10 digits)
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/; // Only digits, exactly 10 characters
    return phoneRegex.test(phone);
  };

  const handleSendOtp = async () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    setLoadingSendOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/send-otp`, {
        email,
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setOtpSent(true);
          toast.success("OTP sent to your email!");
        } else {
          toast.error(data.msg || "Failed to send OTP");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoadingSendOtp(false);
    }
  };

  const handleVerifyOtp = async () => {
    setLoadingVerifyOtp(true);
    try {
      const response = await axios.post(`${backendUrl}/api/auth/verify-otp`, {
        email,
        otp,
      });

      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          setEmailVerified(true);
          toast.success("Email verified successfully!");
        } else {
          toast.error(data.msg || "Invalid OTP");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setLoadingVerifyOtp(false);
    }
  };
  const handleRegister = async (e) => {
    e.preventDefault();

    // Check if email is verified
    if (!emailVerified) {
      toast.error("Please verify your email first.");
      return;
    }

    // Validate phone number
    if (!validatePhoneNumber(phone)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    setLoadingRegister(true);
    try {
      const response = await axios.post(
        `${backendUrl}/api/auth/register`,
        {
          email,
          username,
          password,
          phone,
          otp,
        },
        {
          params: referralCode ? { ref: referralCode } : {}, // Pass referral code as a query parameter
        }
      );
      if (response.status === 200) {
        const data = response.data;
        if (data.success) {
          toast.success("Registration successful!", {
            onClose: () => (window.location.href = "/login"),
          });
        } else {
          // Handle registration errors based on the backend's response message
          toast.error(data.msg || "Registration failed");
        }
      }
    } catch (error) {
      console.error("Error:", error);
      // Display the server's error message if available
      const errorMsg =
        error.response && error.response.data && error.response.data.msg
          ? error.response.data.msg
          : "An error occurred. Please try again.";
      toast.error(errorMsg);
    } finally {
      setLoadingRegister(false);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="register-page-container">
        {/* left section */}
        <div className="register-left">
          <div className="register-container">
            <div className="register-header-h2">
              <h2>Register</h2>
            </div>

            <div className="register-form-container">
              <form onSubmit={handleRegister}>
                <div className="form-group">
                  <label htmlFor="email">
                    <div className="form-label-register">
                      <span>Email</span>
                    </div>
                    <div className="form-input">
                      <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        disabled={otpSent}
                      />
                      <button
                        type="button"
                        className="button-84"
                        onClick={handleSendOtp}
                        disabled={loadingSendOtp || otpSent}
                      >
                        {loadingSendOtp
                          ? "Sending OTP..."
                          : otpSent
                          ? "OTP Sent"
                          : "Send OTP"}
                      </button>
                    </div>
                  </label>
                  {otpSent && !emailVerified && (
                    <div className="form-group">
                      <div className="form-label-register">
                        <label>OTP</label> 
                      </div>
                      <div className="form-input">
                        <input
                          type="text"
                          name="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          required
                        />
                        <button
                          type="button"
                          className="button-84"
                          role="button"
                          onClick={handleVerifyOtp}
                          disabled={loadingVerifyOtp}
                        >
                          {loadingVerifyOtp ? "Verifying..." : "Verify OTP"}
                        </button>
                      </div>
                    </div>
                  )}

                  <label htmlFor="username">
                    <div className="form-label-register">
                      <span>Username</span>
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                      />
                    </div>
                  </label>

                  <label htmlFor="password">
                    <div className="form-label-register">
                      <span>Password</span>
                    </div>
                    <div className="form-input">
                      <input
                        type={passwordVisible ? "text" : "password"}
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="password-toggle-icon"
                      >
                        <Icon icon={passwordVisible ? eye : eyeOff} size={20} />
                      </span>
                    </div>
                  </label>

                  <label htmlFor="phone">
                    <div className="form-label-register">
                      <span>Phone</span>
                    </div>
                    <div className="form-input">
                      <input
                        type="text"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                      />
                    </div>
                  </label>

                  <button type="submit" className="register-button">
                    Register
                  </button>
                </div>
                <div className="message-container">
                  <p className="message-item">
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* right section */}
        <div className="register-right">
          <img src={rightImage} alt="Kitchen Scene" />
        </div>
      </div>
    </>
  );
};

export default Register;
